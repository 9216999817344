/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Send',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M13.875 2.733c.771-.163 1.354.689.922 1.348l-5.233 7.995c-.271.414-.828.525-1.237.246l-1.355-.924-2.2 1.767a.55.55 0 0 1-.894-.429V9.221l-2.547-2.42c-.516-.49-.27-1.357.426-1.505l12.118-2.564zm-8.897 7.305v1.552l1.044-.839-1.044-.712zm.444-1.029l3.342 2.28 4.253-6.498-7.595 4.217zm6.124-4.659L2.388 6.288l2.091 1.987 7.067-3.925z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M20.859 4.519c.96-.203 1.685.857 1.148 1.679l-7.85 11.993a1.1 1.1 0 0 1-1.54.306l-2.59-1.767-3.268 2.641a.6.6 0 0 1-.976-.496l.244-4.954-3.875-3.682a1.1 1.1 0 0 1 .53-1.874l18.177-3.846zM7.186 14.792l-.138 2.804 1.945-1.571-1.807-1.232zm.52-1.098l5.504 3.755 7.004-10.7-12.507 6.945zm11.032-7.499L3.117 9.5l3.567 3.389 12.054-6.694zM2.979 9.369z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M27.836 6.338a1.27 1.27 0 0 1 1.322 1.933L18.688 24.266c-.386.59-1.179.751-1.765.359l-3.882-2.601-4.333 3.501-.005-.006a.6.6 0 0 1-1.016-.374l-.745-7.986-3.892-3.524a1.27 1.27 0 0 1 .588-2.178l24.199-5.12zM8.154 17.242l.46 4.924.956-2.907a.6.6 0 0 1 .238-.374c.028-.027.058-.053.092-.075l11.24-7.442-12.985 5.874zm19.365-8.659L11.231 19.367l6.36 4.261c.031.021.073.012.093-.019l9.834-15.026zM10.486 20.313l-1 3.041 2.511-2.029-1.511-1.012zM25.583 8.041L3.885 12.632c-.019.004-.028.011-.033.016s-.014.017-.018.032-.003.028-.001.037.006.017.02.03l3.774 3.417 17.955-8.122z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M44.498 8.534a1.13 1.13 0 0 1 1.134 1.775L24.127 39.042c-.41.548-1.211.606-1.695.122l-4.862-4.854-5.347 4.734c-.177.156-.426.2-.645.113s-.371-.29-.392-.525l-1.056-11.754-7.66-7.675.441-.44-.441.44c-.63-.632-.31-1.711.563-1.895l41.465-8.773zM11.391 26.948l.648 7.22.953-4.835c.054-.274.28-.471.543-.501a1.13 1.13 0 0 1 .257-.225l20.287-12.992-22.688 11.331zm32.115-15.885L14.597 29.577l8.615 8.601 20.294-27.114zm-20.38 27.23zM44.383 9.891l.049-.066-.077.016.028.049zm-2.653.506L3.517 18.483l7.327 7.341L41.73 10.397zM13.989 30.736l-1.211 6.145 3.905-3.456-2.693-2.689z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M59.338 11.411a1.47 1.47 0 0 1 1.478 2.314L32.142 52.036c-.535.715-1.579.79-2.21.159l-6.505-6.494-7.153 6.332a.8.8 0 0 1-1.327-.527l-1.408-15.684L3.316 25.58l.565-.563-.565.563a1.47 1.47 0 0 1 .734-2.471l55.287-11.698zM15.153 35.91l.887 9.882 1.315-6.675a.8.8 0 0 1 .709-.642 1.47 1.47 0 0 1 .343-.303l27.576-17.66-30.83 15.398zm42.992-21.287L19.41 39.43l11.544 11.524 27.191-36.33zm1.05-1.402l.128-.171-.201.043.073.129zm-3.306.555L4.622 24.623l9.829 9.848 41.436-20.695zM18.632 40.914l-1.646 8.352 5.306-4.697-3.66-3.654z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
