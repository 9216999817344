import { ButtonV2, CloseButtonProps, DialogV2Props, InputV2Props, styled } from '@withjoy/joykit';
import { closeButtonOverrides, drawerOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';
import { buttonSharedStates } from '@shared/utils/style/buttons';
import { pxToRem } from '@withjoy/joykit/theme';

export const dialogOverrides: NonNullable<DialogV2Props['overrides']> = {
  Content: { props: { width: ['100%', '375px'] } },
  Body: { props: { paddingX: 6, paddingY: 8, paddingBottom: 7 } }
};

export const drawerOverridesStyles: NonNullable<DialogV2Props['overrides']> = {
  ...drawerOverrides,
  Content: {
    props: {
      ...drawerOverrides.Content?.props,
      boxShadow: '0px -7px 16px -8px rgba(0, 0, 0, 0.3), 0px -10px 27px -5px rgba(44, 41, 37, 0.25)'
    }
  },
  Body: { props: { ...drawerOverrides.Body?.props, paddingY: 8 } }
};

export const inputOverrides: InputV2Props['overrides'] = {
  Root: {
    props: {
      _hover: {
        borderColor: 'mono14'
      }
    }
  }
};

export const StyledActionButton = styled(ButtonV2)`
  ${buttonSharedStates};
`;

export const closeButtonOverrideStyles: CloseButtonProps['overrides'] = {
  Root: { props: { ...closeButtonOverrides.Root?.props, position: 'absolute', top: pxToRem(20), right: pxToRem(20) } },
  Icon: { props: { ...closeButtonOverrides.Icon?.props } }
};
