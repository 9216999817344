import { Box } from '@withjoy/joykit';
import React, { useRef, useState } from 'react';
import { ReactComponent as CheckmarkIconOff } from './assets/todo-off.svg';
import { ReactComponent as CheckmarkIconOn } from './assets/todo-on.svg';
import { ReactComponent as CheckmarkIconOffHover } from './assets/todo-off-hover.svg';
import { ReactComponent as CheckmarkIconActive } from './assets/todo-active.svg';
import { animationTransition } from '@shared/utils/animationTransition';
import Confetti from 'react-confetti';
import { createPortal } from 'react-dom';

interface ChecklistToDoMarkProps {
  completed: boolean;
  toggleCompleted: (e: React.MouseEvent) => void;
  showConfetti?: boolean;
}

export const ChecklistToDoMark: React.FC<ChecklistToDoMarkProps> = ({ completed, toggleCompleted, showConfetti }) => {
  const [confettiActive, setConfettiActive] = useState(false);
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (!completed && showConfetti && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setConfettiPosition({
        x: rect.x + rect.width / 2,
        y: rect.y
      });
      setConfettiActive(true);
    }
    toggleCompleted(e);
  };

  return (
    <>
      {confettiActive &&
        createPortal(
          <Confetti
            numberOfPieces={30}
            recycle={false}
            run={confettiActive}
            confettiSource={{
              x: confettiPosition.x,
              y: confettiPosition.y,
              w: 10,
              h: 10
            }}
            gravity={0.07}
            onConfettiComplete={() => setConfettiActive(false)}
            initialVelocityX={5}
            initialVelocityY={10}
            style={{ zIndex: 1000 }}
          />,
          document.body
        )}
      <Box width={7} height={7} position={'relative'} role="group" ref={buttonRef} onClick={handleClick}>
        <Box
          position={'absolute'}
          opacity={0}
          transition={animationTransition('opacity')}
          _groupHover={{
            opacity: {
              sm: 1
            }
          }}
        >
          <CheckmarkIconOffHover />
        </Box>
        <Box
          position={'absolute'}
          opacity={0}
          transition={animationTransition('opacity')}
          _groupActive={{
            opacity: 1
          }}
        >
          <CheckmarkIconActive />
        </Box>
        <Box>{completed ? <CheckmarkIconOn /> : <CheckmarkIconOff />}</Box>
      </Box>
    </>
  );
};
