import { styled, ButtonV2 } from '@withjoy/joykit';
import { buttonSharedStates } from '@shared/utils/style/buttons';

export const dialogOverrides = { Content: { props: { width: ['540px'] } } };

export const actionsButtonsWidth = ['100%', '180px'];

export const StyledActionButtion = styled(ButtonV2)`
  ${buttonSharedStates};
`;
