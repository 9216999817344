/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Map Marker',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.92007 6.14869C3.92007 3.8954 5.74672 2.06875 8.00001 2.06875C10.2533 2.06875 12.08 3.8954 12.08 6.14869C12.08 7.84445 11.2199 9.81997 10.1996 11.4072C9.69483 12.1924 9.16715 12.8569 8.71942 13.3182C8.49456 13.5499 8.30027 13.7195 8.14798 13.8268C8.07738 13.8766 8.02884 13.9036 8.00001 13.9181C7.97118 13.9036 7.92264 13.8766 7.85205 13.8268C7.69976 13.7195 7.50546 13.5499 7.2806 13.3182C6.83288 12.8569 6.30519 12.1924 5.80044 11.4072C4.78008 9.81997 3.92007 7.84445 3.92007 6.14869ZM8.00001 0.96875C5.13921 0.96875 2.82007 3.28789 2.82007 6.14869C2.82007 8.15689 3.81204 10.3483 4.87515 12.002C5.41214 12.8374 5.98406 13.5618 6.49126 14.0844C6.74387 14.3446 6.99086 14.5656 7.21837 14.726C7.41754 14.8664 7.69961 15.0326 8.00001 15.0326C8.30041 15.0326 8.58248 14.8664 8.78165 14.726C9.00916 14.5656 9.25616 14.3446 9.50876 14.0844C10.016 13.5618 10.5879 12.8374 11.1249 12.002C12.188 10.3483 13.18 8.15689 13.18 6.14869C13.18 3.28789 10.8608 0.96875 8.00001 0.96875ZM6.69803 6.14869C6.69803 5.42963 7.28095 4.84672 8.00001 4.84672C8.71907 4.84672 9.30199 5.42963 9.30199 6.14869C9.30199 6.86776 8.71907 7.45067 8.00001 7.45067C7.28095 7.45067 6.69803 6.86776 6.69803 6.14869ZM8.00001 3.74672C6.67344 3.74672 5.59803 4.82212 5.59803 6.14869C5.59803 7.47527 6.67344 8.55067 8.00001 8.55067C9.32659 8.55067 10.402 7.47527 10.402 6.14869C10.402 4.82212 9.32659 3.74672 8.00001 3.74672Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.437 9.041c0-3.73 2.886-6.81 6.52-6.835A.61.61 0 0 1 12 2.204c3.653 0 6.563 3.091 6.563 6.837 0 5.975-3.986 10.694-5.527 12.298a1.401 1.401 0 0 1-1.036.456c-.393 0-.772-.165-1.036-.456-1.54-1.605-5.527-6.34-5.527-12.298zM12 3.406c.013 0 .026 0 .039-.002 2.921.022 5.324 2.516 5.324 5.637 0 5.489-3.702 9.916-5.199 11.474a.597.597 0 0 0-.015.016.202.202 0 0 1-.149.064.202.202 0 0 1-.149-.064.388.388 0 0 0-.015-.016C10.34 18.957 6.637 14.513 6.637 9.04c0-3.134 2.423-5.635 5.363-5.635zM9.332 8.729A2.67 2.67 0 1 1 12 11.4a2.67 2.67 0 0 1-2.668-2.67zM12 4.86a3.87 3.87 0 0 0-3.868 3.87A3.87 3.87 0 1 0 12 4.86z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
