import React from 'react';
import { TextV2 } from '@withjoy/joykit';
import { UserGravatar } from './LetterAvatar.styles';

function getInitials(name: string): string {
  return (name.match(/\b\w/g) || []).slice(0, 2).join('').toUpperCase();
}

export const LetterAvatar: React.FC<{ name: Maybe<string> }> = ({ name }) => {
  const initials = getInitials(name || '');
  if (!initials) {
    return null;
  }
  return (
    <UserGravatar height={9} width={9}>
      <TextV2 component="span" typographyVariant="body2">
        {initials}
      </TextV2>
    </UserGravatar>
  );
};
