import { useEffect } from 'react';
import { withWindow } from '@shared/utils/withWindow';
import { previewMessageBusSubject } from '@shared/utils/previewMessageBus';

export const useSendIframeFocusOutMessage = (iframeContainerRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    // send message to iframe on click outside of the iframe
    const handleClickOutside = (e: MouseEvent) => {
      const el = e.target;
      const domNode = iframeContainerRef.current;

      if (!(domNode && el instanceof Node && domNode.contains(el))) {
        previewMessageBusSubject.next({
          type: 'sendMessage',
          payload: {
            message: {
              action: 'iframeParentFocus',
              source: 'joy'
            }
          }
        });
      }
    };

    withWindow(window => {
      window.document.addEventListener('mousedown', handleClickOutside);
    });

    return () => {
      withWindow(window => {
        window.document.removeEventListener('mousedown', handleClickOutside);
      });
    };
  }, [iframeContainerRef]);
};
