/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Photos',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M2.201 3.063a1.1 1.1 0 0 0-1.1 1.1v15.675a1.1 1.1 0 0 0 1.1 1.1h19.598a1.1 1.1 0 0 0 1.1-1.1V4.163a1.1 1.1 0 0 0-1.1-1.1H2.201zm.1 16.675v-7.552L6.027 8.46l11.277 11.277H2.301zM5.249 7.541l-2.948 2.948V4.263h19.398v9.835l-2.143-2.143a1.1 1.1 0 0 0-1.556 0l-3.391 3.391-7.805-7.805a1.1 1.1 0 0 0-1.556 0zm10.209 8.653L19 19.737h2.699v-3.942l-2.921-2.921-3.32 3.32zm-1.896-7.317a.87.87 0 1 1 1.743 0 .87.87 0 1 1-1.743 0zm.871-2.071a2.07 2.07 0 1 0 0 4.143 2.07 2.07 0 1 0 0-4.143z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M19.149 9.642a2.38 2.38 0 0 0-2.378 2.378 2.38 2.38 0 0 0 2.378 2.378 2.38 2.38 0 0 0 2.378-2.378 2.38 2.38 0 0 0-2.378-2.378zm-1.178 2.378a1.18 1.18 0 0 1 1.178-1.178 1.18 1.18 0 0 1 1.178 1.178 1.18 1.18 0 0 1-1.178 1.178 1.18 1.18 0 0 1-1.178-1.178zM4.057 5.328a1.1 1.1 0 0 0-1.1 1.1v19.147a1.1 1.1 0 0 0 1.1 1.1h23.887a1.1 1.1 0 0 0 1.1-1.1V6.428a1.1 1.1 0 0 0-1.1-1.1H4.057zm.1 20.147v-9.301l4.626-4.626L22.71 25.475H4.157zm3.849-14.846l-3.849 3.848V6.528h23.687v12.31l-2.877-2.877a1.1 1.1 0 0 0-1.556 0l-4.259 4.259-9.592-9.592a1.1 1.1 0 0 0-1.556 0zm11.996 10.44l4.405 4.406h3.437v-4.94l-3.654-3.654-4.188 4.188z"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            fill="currentColor"
            d="M31 12.375c-2.002 0-3.625 1.623-3.625 3.625s1.623 3.625 3.625 3.625 3.625-1.623 3.625-3.625-1.623-3.625-3.625-3.625zM28.625 16c0-1.312 1.063-2.375 2.375-2.375s2.375 1.063 2.375 2.375-1.063 2.375-2.375 2.375-2.375-1.063-2.375-2.375zM3.5 6.375c-.621 0-1.125.504-1.125 1.125v33c0 .621.504 1.125 1.125 1.125h41c.621 0 1.125-.504 1.125-1.125v-33c0-.621-.504-1.125-1.125-1.125h-41zm.125 34V24.538l8.195-8.195 24.032 24.032H3.625zm7.399-25.004L3.625 22.77V7.625h40.75v22.506l-5.76-5.76c-.439-.439-1.152-.439-1.591 0l-7.704 7.705-16.705-16.705c-.439-.439-1.152-.439-1.591 0zm19.18 17.589l7.415 7.416h6.756v-8.477l-6.555-6.555-7.616 7.616z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
