import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { ChecklistItemActions, ChecklistSubItemActions } from './DashboardChecklist.types';
import { Stat, StatCard } from './components/DashboardStats';

export const telemetryObject = createTelemetryObject({
  actions: {
    // Checklist
    checklistDataLoaded: () => ({
      action: 'ChecklistDataLoaded',
      category: 'DashboardChecklist'
    }),
    toggleChecklist: (isExpanded: boolean) => ({
      action: 'ToggleExpandedViewInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        toggle: isExpanded ? 'collapse' : 'expand'
      }
    }),
    openChecklistItem: (action: ChecklistItemActions) => ({
      action: 'ChecklistItemInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        action
      }
    }),
    changeReminderDate: (action: ChecklistItemActions, reminderAt: string | null, reminderFrom: 'list' | 'modal') => ({
      action: 'ChangeReminderDateInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        action,
        reminderAt: reminderAt || 'Remove Reminder',
        reminderDifferenceInDays: reminderAt ? Math.ceil((new Date(reminderAt).getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : 0,
        reminderFrom
      }
    }),
    completeChecklistItem: (action: ChecklistItemActions, completedAt: string | null) => ({
      action: 'CompleteChecklistItemInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        action,
        completed: !!completedAt
      }
    }),
    completeChecklistSubItem: (action: ChecklistSubItemActions, completedAt: string | null) => ({
      action: 'CompleteChecklistSubItemInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        action,
        completed: !!completedAt
      }
    }),
    clickChecklistSubItem: (action: ChecklistSubItemActions) => ({
      action: 'ChecklistSubItemInteracted',
      category: 'DashboardChecklist',
      extraInfo: {
        action
      }
    }),
    supportLinkClicked: (link: 'helpCenter' | 'support') => ({
      action: 'SupportLinkClicked',
      category: 'DashboardChecklist',
      extraInfo: {
        link
      }
    }),
    // Stats
    statsDataLoaded: () => ({
      action: 'StatsDataLoaded',
      category: 'DashboardChecklist'
    }),
    statItemClicked: (item: Stat['id'], card: StatCard['id']) => ({
      action: 'StatItemClicked',
      category: 'DashboardChecklist',
      extraInfo: {
        item,
        card
      }
    })
  }
});

export const { useTelemetry: useDashboardChecklistTelemetry } = createTelemetry(telemetryObject);
