import React, { useContext, useEffect } from 'react';
import { Route, RouteProps, useRouteMatch } from '@react-router';
import { EventUserStatus } from '@graphql/generated';
import { AnalyticsContext } from '@shared/core';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { isInIframe } from '@shared/utils/isInIframe';
import { useFullPageLoader } from '@shared/components/JoyLogoLoader/FullPageLoaderProvider';
import { wrapWithProtectedAdminRouteHealthProvider, useProtectedAdminRouteHealth } from './ProtectedAdminRoute.health';

interface Props extends RouteProps {
  eagerlyLoadRoute?: boolean;
  disableDefaultLoader?: boolean;
  isNonEditAdminRoute?: boolean;
  loader?: JSX.Element;
}

// TODO: https://withjoy.atlassian.net/browse/ENG-2201
// Consolidate code using native location API
const buildUrlAndRedirect = (path: string) => {
  window.location.href = window.location.origin + path;
};

export const ProtectedAdminRoute: React.FC<Props> = wrapWithProtectedAdminRouteHealthProvider(
  ({ eagerlyLoadRoute, disableDefaultLoader, loader, isNonEditAdminRoute, ...routeProps }) => {
    const { eventUserId, hasIdentifiedUserOnce, isLoggedIn, role, auth0Id, fetching } = useEventUserRole();
    const analytics = useContext(AnalyticsContext);
    const setLoaderVisibility = useFullPageLoader({
      key: 'ProtectedAdminRoute',
      initialIsLoading: !disableDefaultLoader && !hasIdentifiedUserOnce
    });
    const { onLoadComplete } = useProtectedAdminRouteHealth();

    useEffect(() => {
      if (!disableDefaultLoader) {
        setLoaderVisibility(!hasIdentifiedUserOnce);
      }
    }, [disableDefaultLoader, setLoaderVisibility, hasIdentifiedUserOnce]);

    useEffect(() => {
      if (hasIdentifiedUserOnce) {
        onLoadComplete();
      }
    }, [hasIdentifiedUserOnce, onLoadComplete]);

    const match = useRouteMatch<{ eventHandle: string }>(isNonEditAdminRoute ? '/:eventHandle' : '/:eventHandle/edit');
    if (!match) {
      throw new Error('ProtectedAdminRoute is being used for a non admin route');
    }

    if ((!hasIdentifiedUserOnce || fetching) && !eagerlyLoadRoute) {
      return loader || null;
    }

    const trackInvalidAccess = () => {
      analytics.track({
        category: 'admin',
        action: 'AccessAdminRoute',
        actionType: isLoggedIn ? 'error' : undefined,
        extraInfo: { isLoggedIn, eventUserId, auth0Id, role, route: location.pathname }
      });
    };

    if (!isLoggedIn && isInIframe()) {
      // unfortunate work around for bookmarklet
      // importing the NoAuthDialog (DialogV2) directly causes an eslint mismatch during the client build
      return <Route {...routeProps} />;
    }

    if (hasIdentifiedUserOnce) {
      if (role === EventUserStatus.crasher) {
        // i believe what's happening here is ...
        //   redirect to the Joy Login page, as rendered by the monorepo (joy.git);
        //   our Auth0 OAuth login process requires full-page redirects,
        //   eventually resulting in a callback to `/auth` which provides our JWT token
        //   (which is also handled by the monorepo)

        const redirectPath = isLoggedIn ? `/${match.params.eventHandle}` : `/login?prev=${encodeURIComponent(window.location.pathname)}`;
        trackInvalidAccess();
        buildUrlAndRedirect(redirectPath);
        return null;
      } else if (role === EventUserStatus.guest) {
        trackInvalidAccess();
        buildUrlAndRedirect(`/${match.params.eventHandle}`);
        return null;
      }
    }

    return <Route {...routeProps} />;
  }
);
