import { createTelemetry, createTelemetryObject } from '@shared/core';

const telemObject = createTelemetryObject({
  actions: {
    eventURLChanged: (eventId: string, nextValue: string, originalValue: string, persistedValue: string, telemetryCategory: string) => ({
      action: 'HandleChanged',
      category: telemetryCategory,
      extraInfo: {
        name: 'handleChanged',
        eventId,
        nextValue,
        originalValue,
        persistedValue
      }
    })
  }
});

const { TelemetryProvider: EditEventURLTelemetryProvider, useTelemetry: useEditEventURLTelemetry } = createTelemetry(telemObject);

export { EditEventURLTelemetryProvider, useEditEventURLTelemetry };
