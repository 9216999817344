import { PreviewDevice, UserDesignPreferences } from './WebsiteDesigner.types';
import { DesignLayoutType } from '@graphql/generated';

/** LEFT PANEL */
export const FULL_WIDTH_VIRTUALIZED_ITEM = 400;
export const SCROLLABLE_GRID_GUTTER = 12;
//Since the scroll is tigh to the level of entire left panel we need enough space to cover all the height of the left panel when we have too many items.
export const LEFT_PANEL_VIRTUALIZE_LIST_HEIGHT = 1200;
export const LEFT_PANEL_DESKTOP_WIDTH = 480;
export const TEMPLATES_LEFT_PANEL_HALF_WIDTH = 0.5;
export const LEFT_PANEL_PADDING = 40;
export const MOBILE_LEFT_PANEL_PADDING = 24;

/** PREVIEW */
export const BLISS_PREVIEW_ASPECT_RATIO = 3 / 2;
export const PREVIEW_DEVICE_DIMENSIONS: {
  [device in PreviewDevice]: {
    width: number;
    height: number;
  };
} = {
  desktop: { width: 1788, height: 1788 / BLISS_PREVIEW_ASPECT_RATIO },
  mobile: { width: 395, height: 719 },
  stationery: { width: 960, height: 960 / BLISS_PREVIEW_ASPECT_RATIO }
};

/* GLOBALS */
export const QUERY_PARAMS = {
  Tab: 'tab',
  Trigger: 'trigger',
  PreviewRoute: 'previewRoute'
};

export const TRIGGER_PARAM_VALUES = {
  PagePhotoEditor: 'pagephotoeditor'
};

export const DEFAULT_USER_PREFERENCES: UserDesignPreferences = {
  theme: {
    id: '',
    themeId: 'blank'
  },
  themeId: 'blank',
  eventDesignId: '',
  accent: 'none',
  layout: {
    id: '',
    type: DesignLayoutType.aloha
  },
  customCss: '',
  colorPalette: {
    backgroundColor: {
      paletteId: '',
      value: 'palevioletred'
    },
    foregroundColor: {
      paletteId: '',
      value: 'palevioletred'
    },
    textColor: {
      paletteId: '',
      value: '#000000'
    },
    customBackgroundColor: {
      paletteId: '',
      value: '#FFFFFF'
    },
    useLightForegroundColor: false
  },
  fonts: {
    header: { id: 'temp', fontFamily: 'temp' }
  },
  fontPacks: [],
  useEventColorsForTheme: false,
  alohaPreferences: {
    useSuggestedArtBackgroundColor: true
  },
  brannanPreferences: {
    useSuggestedArtBackgroundColor: true,
    innerFrameBackgroundTransparency: null,
    innerFrameBorder: null
  }
};

/** SHARED STYLES */
export const LEGACY_VALUE_LIGHT_TEXT_HEX = '#FFFFFF';
export const LEGACY_VALUE_DARK_TEXT_HEX = '#3a3c3e';

export const LARGE_BORDER_WIDTH = 2;
