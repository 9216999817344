/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Tip',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <g id="Union">
            <path
              d="M8.00006 0.314453C8.30382 0.314453 8.55006 0.560697 8.55006 0.864453V2.40372C8.55006 2.70748 8.30382 2.95372 8.00006 2.95372C7.69631 2.95372 7.45006 2.70748 7.45006 2.40372V0.864453C7.45006 0.560697 7.69631 0.314453 8.00006 0.314453Z"
              fill="currentColor"
            />
            <path
              d="M4.16831 1.89919C3.99408 1.65037 3.65113 1.5899 3.40231 1.76412C3.15348 1.93835 3.09301 2.2813 3.26724 2.53012L4.15013 3.79102C4.32435 4.03984 4.6673 4.10031 4.91613 3.92608C5.16495 3.75186 5.22542 3.40891 5.05119 3.16008L4.16831 1.89919Z"
              fill="currentColor"
            />
            <path
              d="M12.5979 1.76408C12.8467 1.9383 12.9072 2.28125 12.7329 2.53008L11.85 3.79097C11.6758 4.03979 11.3329 4.10026 11.084 3.92604C10.8352 3.75181 10.7747 3.40886 10.949 3.16004L11.8319 1.89914C12.0061 1.65032 12.349 1.58985 12.5979 1.76408Z"
              fill="currentColor"
            />
            <path
              d="M1.17258 5.26004C0.887146 5.15615 0.571533 5.30332 0.467642 5.58876C0.363751 5.8742 0.510924 6.18981 0.796362 6.2937L2.2428 6.82016C2.52824 6.92405 2.84385 6.77688 2.94774 6.49144C3.05163 6.206 2.90446 5.89039 2.61902 5.7865L1.17258 5.26004Z"
              fill="currentColor"
            />
            <path
              d="M13.0525 10.1692C13.1564 9.88377 13.472 9.73659 13.7574 9.84048L15.2039 10.3669C15.4893 10.4708 15.6365 10.7864 15.5326 11.0719C15.4287 11.3573 15.1131 11.5045 14.8276 11.4006L13.3812 10.8741C13.0958 10.7703 12.9486 10.4546 13.0525 10.1692Z"
              fill="currentColor"
            />
            <path
              d="M15.2037 6.29379C15.4892 6.1899 15.6364 5.87429 15.5325 5.58885C15.4286 5.30341 15.113 5.15624 14.8275 5.26013L13.3811 5.78659C13.0956 5.89048 12.9485 6.2061 13.0524 6.49154C13.1563 6.77697 13.4719 6.92415 13.7573 6.82025L15.2037 6.29379Z"
              fill="currentColor"
            />
            <path
              d="M2.94762 10.1693C3.05151 10.4547 2.90434 10.7703 2.6189 10.8742L1.17246 11.4007C0.887027 11.5046 0.571414 11.3574 0.467523 11.072C0.363632 10.7865 0.510805 10.4709 0.796243 10.367L2.24268 9.84058C2.52812 9.73669 2.84373 9.88386 2.94762 10.1693Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5001 12.8506C10.5001 12.3501 10.7625 11.8949 11.1228 11.5475C11.9719 10.729 12.5001 9.57989 12.5001 8.30737C12.5001 5.82209 10.4854 3.80737 8.00011 3.80737C5.51483 3.80737 3.50011 5.82209 3.50011 8.30737C3.50011 9.57985 4.02827 10.729 4.87732 11.5475C5.23766 11.8948 5.50005 12.35 5.50005 12.8505V14.1865C5.50005 15.015 6.17162 15.6865 7.00005 15.6865H9.00005C9.82848 15.6865 10.5001 15.015 10.5001 14.1865V12.8506ZM6.49552 12.124C6.33108 11.5612 5.99413 11.0962 5.64076 10.7555C4.99786 10.1358 4.60011 9.26897 4.60011 8.30737C4.60011 6.4296 6.12234 4.90737 8.00011 4.90737C9.87788 4.90737 11.4001 6.4296 11.4001 8.30737C11.4001 9.269 11.0023 10.1358 10.3594 10.7556C10.006 11.0962 9.66907 11.5612 9.50462 12.124H6.49552ZM6.60005 13.224V14.1865C6.60005 14.4074 6.77914 14.5865 7.00005 14.5865H9.00005C9.22097 14.5865 9.40005 14.4074 9.40005 14.1865V13.224H6.60005Z"
              fill="currentColor"
            />
          </g>
        </>
      )
    },
    '24': {
      viewBox: '0 0 22 22',
      path: (
        <>
          <g id="Union">
            <path
              d="M11.0001 0.353516C11.3315 0.353516 11.6001 0.622145 11.6001 0.953516V3.15121C11.6001 3.48258 11.3315 3.75121 11.0001 3.75121C10.6688 3.75121 10.4001 3.48258 10.4001 3.15121V0.953516C10.4001 0.622145 10.6688 0.353516 11.0001 0.353516Z"
              fill="currentColor"
            />
            <path
              d="M5.37766 2.53709C5.18759 2.26564 4.81347 2.19967 4.54202 2.38974C4.27058 2.57981 4.20461 2.95393 4.39468 3.22538L5.65522 5.02562C5.84529 5.29706 6.21942 5.36303 6.49086 5.17297C6.7623 4.9829 6.82827 4.60877 6.6382 4.33733L5.37766 2.53709Z"
              fill="currentColor"
            />
            <path
              d="M17.4582 2.38968C17.7296 2.57974 17.7956 2.95387 17.6055 3.22532L16.345 5.02556C16.1549 5.297 15.7808 5.36297 15.5093 5.1729C15.2379 4.98284 15.1719 4.60871 15.362 4.33727L16.6225 2.53702C16.8126 2.26558 17.1867 2.19961 17.4582 2.38968Z"
              fill="currentColor"
            />
            <path
              d="M1.18871 7.40339C0.877326 7.29005 0.53302 7.4506 0.419685 7.76199C0.306349 8.07338 0.466902 8.41768 0.778288 8.53102L2.84344 9.28267C3.15483 9.39601 3.49913 9.23546 3.61247 8.92407C3.72581 8.61268 3.56525 8.26838 3.25387 8.15504L1.18871 7.40339Z"
              fill="currentColor"
            />
            <path
              d="M18.3876 14.3018C18.5009 13.9904 18.8452 13.8298 19.1566 13.9432L21.2218 14.6948C21.5331 14.8082 21.6937 15.1525 21.5804 15.4639C21.467 15.7753 21.1227 15.9358 20.8113 15.8225L18.7462 15.0708C18.4348 14.9575 18.2742 14.6132 18.3876 14.3018Z"
              fill="currentColor"
            />
            <path
              d="M21.2218 8.53096C21.5332 8.41763 21.6938 8.07332 21.5805 7.76194C21.4671 7.45055 21.1228 7.29 20.8114 7.40333L18.7463 8.15499C18.4349 8.26832 18.2743 8.61263 18.3877 8.92402C18.501 9.2354 18.8453 9.39595 19.1567 9.28262L21.2218 8.53096Z"
              fill="currentColor"
            />
            <path
              d="M3.61243 14.3017C3.72577 14.6131 3.56522 14.9574 3.25383 15.0708L1.18868 15.8224C0.877289 15.9357 0.532984 15.7752 0.419649 15.4638C0.306313 15.1524 0.466865 14.8081 0.778252 14.6948L2.84341 13.9431C3.15479 13.8298 3.4991 13.9903 3.61243 14.3017Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.0002 18.5592C14.0002 17.8138 14.4317 17.1498 15.0174 16.6887C16.5293 15.4986 17.5001 13.6519 17.5001 11.5785C17.5001 7.98864 14.59 5.07849 11.0001 5.07849C7.41027 5.07849 4.50012 7.98864 4.50012 11.5785C4.50012 13.6519 5.47096 15.4986 6.98287 16.6888C7.56862 17.1499 8.00018 17.8138 8.00018 18.5593V19.6473C8.00018 20.7519 8.89561 21.6473 10.0002 21.6473H12.0002C13.1047 21.6473 14.0002 20.7519 14.0002 19.6473V18.5592ZM9.01216 17.4817C8.75263 16.7559 8.2561 16.1639 7.72512 15.7459C6.48981 14.7735 5.70012 13.2685 5.70012 11.5785C5.70012 8.65138 8.07301 6.27849 11.0001 6.27849C13.9272 6.27849 16.3001 8.65138 16.3001 11.5785C16.3001 13.2685 15.5105 14.7734 14.2752 15.7458C13.7442 16.1638 13.2477 16.7558 12.9882 17.4817H9.01216ZM9.20018 18.6817V19.6473C9.20018 20.0892 9.55835 20.4473 10.0002 20.4473H12.0002C12.442 20.4473 12.8002 20.0892 12.8002 19.6473V18.6817H9.20018Z"
              fill="currentColor"
            />
          </g>
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path d="M16 .067a.6.6 0 0 1 .6.6V3.7a.6.6 0 0 1-1.2 0V.667a.6.6 0 0 1 .6-.6Z" fill="currentColor" />{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.02 7.058a8.824 8.824 0 0 0-5.077 1.594 8.88 8.88 0 0 0-3.25 4.228 8.92 8.92 0 0 0-.249 5.333 8.891 8.891 0 0 0 2.84 4.515l.007.005a3.403 3.403 0 0 1 1.237 2.587v4.157c0 .65.257 1.275.716 1.736.46.46 1.082.72 1.733.72h4.043c.652 0 1.276-.26 1.735-.721.46-.462.717-1.088.717-1.74v-4.156c0-.543.08-.868.317-1.383.21-.454.516-.857.897-1.18a8.89 8.89 0 0 0 2.86-4.501 8.92 8.92 0 0 0-.225-5.334 8.882 8.882 0 0 0-3.23-4.243 8.824 8.824 0 0 0-5.07-1.617ZM19.272 28.6v.873c0 .335-.132.656-.367.892a1.248 1.248 0 0 1-.885.368h-4.043c-.33 0-.648-.131-.882-.367a1.261 1.261 0 0 1-.367-.89V28.6h6.544Zm0-1.2v-1.791h-6.544V27.4h6.544Zm.086-2.991c.068-.322.18-.627.342-.979a4.63 4.63 0 0 1 1.211-1.593l.002-.001a7.69 7.69 0 0 0 2.474-3.894 7.72 7.72 0 0 0-.195-4.617 7.682 7.682 0 0 0-2.794-3.67 7.624 7.624 0 0 0-8.767-.02 7.68 7.68 0 0 0-2.81 3.657 7.72 7.72 0 0 0-.216 4.616 7.69 7.69 0 0 0 2.453 3.903 4.603 4.603 0 0 1 1.57 2.598h6.73Z"
            fill="#7139BF"
          />
          <path
            d="M6.058 2.86a.6.6 0 0 1 .837.135L8.671 5.45a.6.6 0 1 1-.972.703L5.923 3.7a.6.6 0 0 1 .135-.838ZM1.52 11.184a.6.6 0 1 0-.373 1.14l2.874.938a.6.6 0 0 0 .372-1.14l-2.873-.938ZM4.777 19.303a.6.6 0 0 1-.384.757l-2.873.938a.6.6 0 1 1-.373-1.141l2.874-.938a.6.6 0 0 1 .756.384ZM27.98 18.92a.6.6 0 0 0-.372 1.14l2.873.938a.6.6 0 1 0 .372-1.141l-2.873-.938ZM31.237 11.568a.6.6 0 0 1-.384.756l-2.873.938a.6.6 0 0 1-.372-1.14l2.873-.938a.6.6 0 0 1 .756.384ZM26.077 3.699a.6.6 0 0 0-.972-.704L23.33 5.45a.6.6 0 0 0 .973.703L26.077 3.7Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 40 42',
      path: (
        <>
          <g id="Union">
            <path
              d="M20.6251 1.24414C20.6251 0.898963 20.3453 0.619141 20.0001 0.619141C19.655 0.619141 19.3751 0.898963 19.3751 1.24414V5.38181C19.3751 5.72698 19.655 6.00681 20.0001 6.00681C20.3453 6.00681 20.6251 5.72698 20.6251 5.38181V1.24414Z"
              fill="currentColor"
            />
            <path
              d="M8.13075 4.36161C8.41351 4.16362 8.80322 4.23234 9.00121 4.51509L11.3745 7.90447C11.5725 8.18722 11.5037 8.57694 11.221 8.77493C10.9382 8.97291 10.5485 8.90419 10.3505 8.62144L7.97727 5.23206C7.77928 4.94931 7.848 4.55959 8.13075 4.36161Z"
              fill="currentColor"
            />
            <path
              d="M32.023 5.23199C32.221 4.94924 32.1523 4.55952 31.8695 4.36154C31.5868 4.16355 31.1971 4.23227 30.9991 4.51502L28.6258 7.9044C28.4278 8.18715 28.4965 8.57687 28.7793 8.77485C29.062 8.97284 29.4518 8.90412 29.6497 8.62137L32.023 5.23199Z"
              fill="currentColor"
            />
            <path
              d="M0.554225 14.2352C0.672283 13.9108 1.03093 13.7436 1.3553 13.8617L5.24343 15.2768C5.56779 15.3949 5.73503 15.7535 5.61697 16.0779C5.49892 16.4023 5.14027 16.5695 4.8159 16.4514L0.92777 15.0363C0.603409 14.9182 0.436167 14.5596 0.554225 14.2352Z"
              fill="currentColor"
            />
            <path
              d="M35.1842 26.1744C34.8598 26.0563 34.5011 26.2236 34.3831 26.5479C34.265 26.8723 34.4323 27.231 34.7566 27.349L38.6448 28.7642C38.9691 28.8822 39.3278 28.715 39.4458 28.3906C39.5639 28.0663 39.3966 27.7076 39.0723 27.5896L35.1842 26.1744Z"
              fill="currentColor"
            />
            <path
              d="M39.4458 14.2353C39.5639 14.5596 39.3966 14.9183 39.0723 15.0363L35.1841 16.4515C34.8598 16.5696 34.5011 16.4023 34.3831 16.0779C34.265 15.7536 34.4323 15.3949 34.7566 15.2769L38.6448 13.8617C38.9691 13.7437 39.3278 13.9109 39.4458 14.2353Z"
              fill="currentColor"
            />
            <path
              d="M5.24342 27.3489C5.56779 27.2309 5.73503 26.8722 5.61697 26.5479C5.49891 26.2235 5.14026 26.0563 4.8159 26.1743L0.927765 27.5895C0.603404 27.7076 0.436162 28.0662 0.55422 28.3906C0.672277 28.7149 1.03093 28.8822 1.35529 28.7641L5.24342 27.3489Z"
              fill="currentColor"
            />
            <path
              d="M13.2637 20.6273L13.268 20.5821C13.4205 19.031 14.1052 17.5724 15.2169 16.4608C16.3285 15.3491 17.7872 14.6643 19.3382 14.5119L19.3834 14.5076C19.4155 14.5046 19.4471 14.4995 19.4779 14.4924C19.773 14.4242 20.0003 14.1707 20.0003 13.8618C20.0003 13.5207 19.7233 13.2417 19.3832 13.268C19.3457 13.2709 19.3082 13.274 19.2708 13.2774C19.2294 13.2812 19.1881 13.2853 19.1468 13.2898C17.3388 13.4837 15.6411 14.2895 14.3434 15.5873C13.0456 16.885 12.2398 18.5827 12.0459 20.3907C12.0415 20.432 12.0373 20.4733 12.0336 20.5147C12.0301 20.5521 12.027 20.5896 12.0241 20.6271C11.9978 20.9672 12.2768 21.2441 12.6179 21.2441C12.9268 21.2441 13.1803 21.0169 13.2485 20.7218C13.2556 20.691 13.2608 20.6594 13.2637 20.6273Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.6548 30.555C26.693 31.3525 26.0001 32.477 26.0001 33.7264V37.8818C26.0001 39.8148 24.4331 41.3818 22.5001 41.3818H17.5001C15.5671 41.3818 14.0001 39.8148 14.0001 37.8818V33.7264C14.0001 32.477 13.3073 31.3525 12.3455 30.555C9.69091 28.3539 8.00013 25.0309 8.00013 21.313C8.00013 14.6856 13.3727 9.31299 20.0001 9.31299C26.6275 9.31299 32.0001 14.6856 32.0001 21.313C32.0001 25.0309 30.3093 28.3539 27.6548 30.555ZM26.8569 29.5927C25.9408 30.3523 25.1541 31.4192 24.8666 32.688H15.1337C14.8462 31.4192 14.0594 30.3523 13.1434 29.5927C10.7629 27.6189 9.25013 24.6432 9.25013 21.313C9.25013 15.3759 14.0631 10.563 20.0001 10.563C25.9372 10.563 30.7501 15.3759 30.7501 21.313C30.7501 24.6432 29.2374 27.6189 26.8569 29.5927ZM15.2501 36.4146V33.938H24.7501V36.4146H15.2501ZM15.2501 37.8818V37.6646H24.7501V37.8818C24.7501 39.1245 23.7428 40.1318 22.5001 40.1318H17.5001C16.2575 40.1318 15.2501 39.1245 15.2501 37.8818Z"
              fill="currentColor"
            />
          </g>
        </>
      )
    },
    '64': {
      viewBox: '0 0 52 56',
      path: (
        <>
          <g id="Union">
            <path
              d="M26.8001 1.6418C26.8001 1.19997 26.4419 0.841797 26.0001 0.841797C25.5583 0.841797 25.2001 1.19997 25.2001 1.6418V7.15868C25.2001 7.60051 25.5583 7.95868 26.0001 7.95868C26.4419 7.95868 26.8001 7.60051 26.8001 7.15868V1.6418Z"
              fill="currentColor"
            />
            <path
              d="M10.1933 5.82568C10.5552 5.57226 11.0541 5.66022 11.3075 6.02215L14.4718 10.5413C14.7253 10.9032 14.6373 11.4021 14.2754 11.6555C13.9135 11.9089 13.4146 11.821 13.1612 11.459L9.99684 6.93987C9.74341 6.57794 9.83137 6.07911 10.1933 5.82568Z"
              fill="currentColor"
            />
            <path
              d="M42.0035 6.93987C42.257 6.57794 42.169 6.0791 41.8071 5.82568C41.4451 5.57226 40.9463 5.66022 40.6929 6.02214L37.5285 10.5413C37.2751 10.9032 37.3631 11.4021 37.725 11.6555C38.0869 11.9089 38.5857 11.821 38.8392 11.459L42.0035 6.93987Z"
              fill="currentColor"
            />
            <path
              d="M0.103582 18.9747C0.254696 18.5595 0.71377 18.3454 1.12895 18.4965L6.31313 20.3834C6.72831 20.5345 6.94238 20.9936 6.79127 21.4088C6.64016 21.824 6.18108 22.0381 5.7659 21.8869L0.58172 20.0001C0.166538 19.8489 -0.0475318 19.3899 0.103582 18.9747Z"
              fill="currentColor"
            />
            <path
              d="M46.2342 34.9134C45.819 34.7623 45.3599 34.9764 45.2088 35.3916C45.0577 35.8067 45.2718 36.2658 45.6869 36.4169L50.8711 38.3038C51.2863 38.4549 51.7454 38.2409 51.8965 37.8257C52.0476 37.4105 51.8335 36.9514 51.4184 36.8003L46.2342 34.9134Z"
              fill="currentColor"
            />
            <path
              d="M51.8963 18.9747C52.0474 19.3898 51.8334 19.8489 51.4182 20L46.234 21.8869C45.8188 22.038 45.3597 21.824 45.2086 21.4088C45.0575 20.9936 45.2716 20.5345 45.6868 20.3834L50.8709 18.4965C51.2861 18.3454 51.7452 18.5595 51.8963 18.9747Z"
              fill="currentColor"
            />
            <path
              d="M6.31294 36.417C6.72813 36.2659 6.9422 35.8068 6.79108 35.3916C6.63997 34.9765 6.18089 34.7624 5.76571 34.9135L0.581533 36.8004C0.166351 36.9515 -0.0477188 37.4106 0.103395 37.8258C0.254509 38.2409 0.713583 38.455 1.12877 38.3039L6.31294 36.417Z"
              fill="currentColor"
            />
            <path
              d="M16.5662 27.5418C16.5822 27.3445 16.6044 27.1482 16.6326 26.9532C16.9232 24.9441 17.8549 23.0682 19.3073 21.6158C20.7597 20.1633 22.6356 19.2317 24.6447 18.941C24.8398 18.9128 25.036 18.8906 25.2333 18.8746C25.2693 18.8717 25.3048 18.8665 25.3396 18.8593C25.712 18.7816 26 18.4638 26 18.076C26 17.6521 25.6559 17.3057 25.2331 17.3353C25.1917 17.3381 25.1503 17.3413 25.109 17.3446C24.8796 17.3633 24.6512 17.3891 24.4244 17.4219C22.0896 17.7598 19.9097 18.8425 18.2219 20.5303C16.534 22.2182 15.4513 24.3981 15.1134 26.7328C15.0806 26.9597 15.0548 27.188 15.0362 27.4175C15.0328 27.4588 15.0297 27.5002 15.0268 27.5416C14.9973 27.9644 15.3437 28.3085 15.7676 28.3085C16.1553 28.3085 16.4732 28.0205 16.5508 27.648C16.5581 27.6133 16.5632 27.5778 16.5662 27.5418Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.1321 40.7841C34.8894 41.802 34.0001 43.249 34.0001 44.8553V50.6587C34.0001 53.144 31.9854 55.1587 29.5001 55.1587H22.5001C20.0149 55.1587 18.0001 53.144 18.0001 50.6587V44.8553C18.0001 43.249 17.1108 41.802 15.8682 40.7841C12.286 37.8499 10.0001 33.392 10.0001 28.4003C10.0001 19.5637 17.1636 12.4003 26.0001 12.4003C34.8367 12.4003 42.0001 19.5637 42.0001 28.4003C42.0001 33.392 39.7142 37.8499 36.1321 40.7841ZM32.4001 44.8553V45.0004H19.6001V44.8553C19.6001 42.6395 18.382 40.775 16.8821 39.5463C13.655 36.9029 11.6001 32.8922 11.6001 28.4003C11.6001 20.4474 18.0472 14.0003 26.0001 14.0003C33.953 14.0003 40.4001 20.4474 40.4001 28.4003C40.4001 32.8922 38.3453 36.9029 35.1182 39.5463C33.6183 40.775 32.4001 42.6395 32.4001 44.8553ZM19.6001 49.2816V46.6004H32.4001V49.2816H19.6001ZM22.5001 53.5587C20.9735 53.5587 19.7224 52.3791 19.6086 50.8816H32.3917C32.2779 52.3791 31.0268 53.5587 29.5001 53.5587H22.5001Z"
              fill="currentColor"
            />
          </g>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
