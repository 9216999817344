/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Close Outline',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <circle fillRule="evenodd" fill="none" stroke="currentColor" cx="12.334" cy="12" r="8.4" strokeWidth="1.2" />
          <g strokeLinecap="round" stroke="currentColor">
            <path d="M15.708 8.626l-6.748 6.748" />
            <path d="M15.708 15.375L8.96 8.626" />
          </g>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
