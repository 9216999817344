import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Box, TextV2, InputV2, Spinner, Flex, useToast } from '@withjoy/joykit';
import { useGetEventHandleAvailabilityLazyQuery } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { FieldInputProps } from 'formik';
import { debounce } from 'lodash-es';

const overrides = {
  StartElement: {
    props: {
      paddingTop: 4,
      paddingLeft: 4,
      paddingBottom: 4,
      paddingRight: 0
    }
  },
  Root: {
    props: {
      boxShadow: '0px 9px 36px -30px rgba(0, 0, 0, 0.04), 0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 3px 8px 0px rgba(0, 0, 0, 0.03)'
    }
  }
};

const SITE_URL_MAX_LENGTH = 60;

export interface DebouncedInputProps {
  eventId?: string;

  setValue: (val: string) => void;
  value: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldInputProps: FieldInputProps<any>;
  initialValue?: string;
  setIsValid: (val: boolean) => void;
  setIsLoading?: (val: boolean) => void;
  onFocus?: () => void;
}

export interface ChangeHandlerProps {
  evt: ChangeEvent<HTMLInputElement>;
  setValue: (val: string) => void;
}

export const DebouncedInputWebsiteUrl = (props: DebouncedInputProps) => {
  const { setValue, eventId, setIsLoading, fieldInputProps, value, setIsValid, initialValue } = props;

  const { t2 } = useTranslation('settings');
  const translations = t2('inputJoyUrl');
  const { toast } = useToast();

  const { prefix, available, notAvailable, error, labelMaxCharacters } = translations;

  const [checkEventHandleAvailability, { data, loading }] = useGetEventHandleAvailabilityLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'network-only',
    onCompleted: data => setIsValid(data.isWebsiteHandleAvailable),
    onError: () => {
      setValue(initialValue || '');
      setIsValid(true);
      toast(error);
    }
  });

  const checkAvailability = useCallback(
    (eventId: string, proposedWebsiteHandle: string) => {
      checkEventHandleAvailability({
        variables: { eventId: eventId, websiteHandle: proposedWebsiteHandle }
      });
    },
    [checkEventHandleAvailability]
  );

  const debouncedEventHandleAvailabilityCheck = useMemo(
    () =>
      debounce(proposedWebsiteHandle => {
        if (eventId) checkAvailability(eventId, proposedWebsiteHandle);
      }, 200),
    [checkAvailability, eventId]
  );

  const checkWebsiteUrl = useCallback((websiteUrl: string) => {
    return /^[a-zA-Z0-9-]+$/.test(websiteUrl);
  }, []);

  const changeHandler = (props: ChangeHandlerProps): void => {
    const { evt, setValue } = props;
    const validUrl = checkWebsiteUrl(evt.target.value);
    setValue(evt.target.value);
    if (evt.target.value === initialValue) {
      setIsValid(true);
    } else if (!evt.target.value || !validUrl) {
      setIsValid(false);
    } else {
      debouncedEventHandleAvailabilityCheck(evt.target.value);
    }
  };

  useEffect(() => {
    if (loading && setIsLoading) setIsLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <InputV2
        overrides={overrides}
        maxLength={SITE_URL_MAX_LENGTH}
        onFocus={() => props.onFocus && props.onFocus()}
        startElement={
          <Box typographyVariant="body2" color="mono8">
            {prefix}
          </Box>
        }
        endElement={loading ? <Spinner /> : undefined}
        {...fieldInputProps}
        onChange={(e: ChangeEvent<HTMLInputElement>) => changeHandler({ evt: e, setValue })}
      />
      <Flex paddingTop={2} justifyContent="space-between" margin={0} height={7}>
        <TextV2 typographyVariant="label2">
          {value.length}/{SITE_URL_MAX_LENGTH} {labelMaxCharacters}
        </TextV2>
        {!loading && data && (
          <Box
            typographyVariant="label2"
            backgroundColor={fieldInputProps.value && checkWebsiteUrl(fieldInputProps.value) && data?.isWebsiteHandleAvailable ? '#E8FFF8' : '#FFE5EA'}
            paddingX="8px"
            paddingY={'4px'}
            borderRadius={'4px'}
          >
            {fieldInputProps.value && checkWebsiteUrl(fieldInputProps.value) && data?.isWebsiteHandleAvailable ? available : notAvailable}
          </Box>
        )}
      </Flex>
    </>
  );
};
