import React, { FC, useEffect, useMemo, useReducer } from 'react';
import { useLocation } from '@react-router';
import { createContext } from '@shared/utils/createContext';
import { InviteAdminDialog, User, Invite } from '@shared/components/InviteAdminDialog/InviteAdminDialog';
import { useEventUserRole } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useToast } from '@withjoy/joykit';
import { CheckFilled } from '@withjoy/joykit/icons';
import {
  useGetEventAdminsAndInviteesLazyQuery,
  useCreateNewAdminInviteMutation,
  useResendEventAdminInviteMutation,
  useRemoveEventAdminUserMutation,
  useCancelEventAdminInviteMutation,
  useTransferEventOwnershipMutation,
  GetEventAdminsAndInviteesQuery
} from '@graphql/generated';
import { AddAdminDialog } from '@shared/components/InviteAdminDialog/components/AddAdminDialog';
import { RevokeAdminDialog } from '@shared/components/InviteAdminDialog/components/RevokeAdminDialog';
import { TransferOwnershipDialog } from '@shared/components/InviteAdminDialog/components/TransferOwnershipDialog';
import { useAuth } from '@shared/components/AuthProvider';
import { useTranslation } from '@shared/core';
import { useSettingsTelemetry } from '@apps/admin/routes/Settings/Settings.telemetry';

type EventUsers = NonNullable<NonNullable<GetEventAdminsAndInviteesQuery['eventById']>['eventUsers']>;
type EventInvitees = NonNullable<NonNullable<GetEventAdminsAndInviteesQuery['eventAdminInvites']>>;

type ModalInfo = Record<string, Maybe<string>> | null;

type DialogsState = {
  ADD_COLLABORATOR: { open: boolean; info: ModalInfo };
  LIST_USERS: { open: boolean; info: ModalInfo };
  REMOVE_COLLABORATOR: { open: boolean; info: ModalInfo };
  TRANSFER_OWNERSHIP: { open: boolean; info: ModalInfo };
};

const initialState: DialogsState = {
  ADD_COLLABORATOR: { open: false, info: null },
  LIST_USERS: { open: false, info: null },
  REMOVE_COLLABORATOR: { open: false, info: null },
  TRANSFER_OWNERSHIP: { open: false, info: null }
};

type DialogsAction =
  | { type: 'ADD_COLLABORATOR'; payload?: ModalInfo }
  | { type: 'LIST_USERS'; payload?: ModalInfo }
  | { type: 'REMOVE_COLLABORATOR'; payload?: ModalInfo }
  | { type: 'TRANSFER_OWNERSHIP'; payload?: ModalInfo }
  | { type: 'CANCEL' };

type InviteAdminDialogProviderContext = {
  openInviteAdminDialog: () => void;
  closeInviteAdminDialog: () => void;
};

const [Provider, useInviteAdminDialogContext] = createContext<InviteAdminDialogProviderContext>({ name: 'InviteAdminDialogProviderContext' });

interface InviteAdminDialogProviderProps {
  eventId: string;
  title: string;
  isDialogView?: boolean;
}

const InviteAdminDialogProvider: FC<InviteAdminDialogProviderProps> = ({ children, eventId, title, isDialogView = true }) => {
  const [dialogs, dispatch] = useReducer((state: DialogsState, action: DialogsAction): DialogsState => {
    switch (action.type) {
      case 'CANCEL':
        return initialState;
      default:
        return {
          ...initialState,
          [action.type]: { open: true, info: action.payload || null }
        };
    }
  }, initialState);

  const [requestEventUsers, { loading: isFetchingAdmins, data: eventUsersData }] = useGetEventAdminsAndInviteesLazyQuery({
    batchMode: 'fast',
    variables: { eventId }
  });

  const { toast } = useToast();
  const { role } = useEventUserRole();
  const isOwnerOrSuperAdmin = role === 'owner' || role === 'superAdmin';
  const {
    currentUser: { profile: userProfile }
  } = useAuth();
  const location = useLocation();
  const { t2 } = useTranslation('yourRegistry');
  const translations = t2('inviteAdminDialog');
  const [inviteUser, { loading: invitingUser }] = useCreateNewAdminInviteMutation();
  const [resendInvite] = useResendEventAdminInviteMutation();
  const [cancelInvite, { loading: cancelingInvite }] = useCancelEventAdminInviteMutation();
  const [transferOwnership, { loading: transferingOwnership }] = useTransferEventOwnershipMutation();
  const [removeAdmin, { loading: removingAdmin }] = useRemoveEventAdminUserMutation();

  const telemetry = useSettingsTelemetry();

  const handleOpen = () => {
    requestEventUsers();
    goToFirstStep();
  };

  useEffect(() => {
    // to trigger the open steps when component is rendered as a page
    if (!isDialogView) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogView]);

  const goToFirstStep = () => dispatch({ type: 'LIST_USERS' });

  const handleClose = () => {
    dispatch({ type: 'CANCEL' });
  };

  const extractOwnerAndCollaborators = (users?: EventUsers) => {
    if (!users) {
      return { owner: undefined, collaborators: [] };
    }
    return users.reduce(
      (acc: { owner?: User; collaborators: User[] }, { auth0Id, status, user: { email, firstName, lastName, emailIsVerified, id } }) => {
        const userObj = { id, email, firstName, lastName, emailIsVerified, auth0Id };
        if (status === 'owner') {
          return { ...acc, owner: userObj };
        }
        if (status === 'admin') {
          return { ...acc, collaborators: [...acc.collaborators, userObj] };
        }
        return acc;
      },
      { owner: undefined, collaborators: [] }
    );
  };

  const extractInvitees = (invites?: EventInvitees | null): Invite[] => {
    if (!invites) {
      return [];
    }
    return invites
      .filter(invite => invite !== null && invite.status === 'open')
      .map(invite => {
        if (invite === null) {
          return {};
        }
        return {
          id: invite.id,
          email: invite.emailId,
          name: invite.name
        };
      });
  };

  const { owner, collaborators, invitees } = useMemo(() => {
    const eventUsers = eventUsersData?.eventById?.eventUsers;
    const { owner, collaborators } = extractOwnerAndCollaborators(eventUsers);

    const eventAdminInvites = eventUsersData?.eventAdminInvites;
    const invitees = extractInvitees(eventAdminInvites);

    return { owner, collaborators, invitees };
  }, [eventUsersData?.eventById?.eventUsers, eventUsersData?.eventAdminInvites]);

  const handleInviteCollaborator = () => {
    telemetry.adminInviteClicked(eventId);
    dispatch({ type: 'ADD_COLLABORATOR' });
  };

  const handleSendInvitation = ({ email, firstName, lastName }: { email: string; firstName: string; lastName: string }) => {
    inviteUser({
      variables: {
        eventId,
        payload: {
          email,
          firstName,
          lastName
        }
      },
      refetchQueries: ['GetEventAdminsAndInvitees']
    })
      .then(() => {
        telemetry.adminInviteSuccess(eventId, email, firstName, lastName);
        toast(translations.invitationHasBeenSent, { icon: <CheckFilled /> });
        goToFirstStep();
      })
      .catch(error => {
        telemetry.adminInviteFailed(eventId, email, firstName, lastName);
        toast(error.message);
      });
  };

  const handleResendInvitation = (eventAdminInviteId: string) => {
    telemetry.adminInviteResendClick(eventId, eventAdminInviteId);
    resendInvite({
      variables: {
        eventId,
        eventAdminInviteId
      },
      refetchQueries: ['GetEventAdminsAndInvitees']
    })
      .then(() => {
        telemetry.adminInviteResendSuccess(eventId, eventAdminInviteId);
        toast(translations.weResentYourInvitation, { icon: <CheckFilled /> });
      })
      .catch(() => {
        telemetry.adminInviteResendFailed(eventId, eventAdminInviteId);
        toast(translations.errorResendingYourInvitation);
      });
  };

  let handleTransferEventOwnershipDialog;
  if (isOwnerOrSuperAdmin) {
    handleTransferEventOwnershipDialog = (auth0Id: string, name: string, email: Maybe<string>) => {
      telemetry.adminTransferOwnershipClicked(eventId, auth0Id);
      dispatch({ type: 'TRANSFER_OWNERSHIP', payload: { auth0Id, name, email } });
    };
  }

  const handleRemoveCollaborator = (auth0Id: string, name: string, email: Maybe<string>) => {
    telemetry.adminInviteRevokeClicked(eventId, auth0Id);
    dispatch({ type: 'REMOVE_COLLABORATOR', payload: { auth0Id, name, mode: 'remove', email } });
  };

  const handleCancelInviteDialog = (eventAdminInviteId: string, name: string) => {
    telemetry.adminInviteCancelClicked(eventId, eventAdminInviteId);
    dispatch({ type: 'REMOVE_COLLABORATOR', payload: { eventAdminInviteId, name, mode: 'cancel' } });
  };

  const handleTransferEventOwnership = () => {
    const auth0Id = dialogs.TRANSFER_OWNERSHIP?.info?.auth0Id;
    if (!auth0Id) return;
    transferOwnership({
      variables: {
        eventId,
        auth0Id
      },
      refetchQueries: ['GetEventAdminsAndInvitees']
    })
      .then(() => {
        telemetry.adminTransferOwnershipSuccess(eventId, auth0Id);
        toast(translations.transferredOwnership, { icon: <CheckFilled /> });
        goToFirstStep();
      })
      .catch(() => {
        telemetry.adminTransferOwnershipFailed(eventId, auth0Id);
        toast(translations.errorTransferingOwnership);
      });
  };

  const handleRevoke = () => {
    const mode = dialogs.REMOVE_COLLABORATOR?.info?.mode;
    switch (mode) {
      case 'cancel':
        const eventAdminInviteId = dialogs.REMOVE_COLLABORATOR?.info?.eventAdminInviteId;
        if (!eventAdminInviteId) return;
        handleCancelInvite(eventAdminInviteId);
        break;
      case 'remove':
        const auth0Id = dialogs.REMOVE_COLLABORATOR?.info?.auth0Id;
        if (!auth0Id) return;
        handleRemoveAdmin(eventId, auth0Id);
        break;
      default:
        break;
    }
  };

  const handleCancelInvite = (eventAdminInviteId: string) => {
    cancelInvite({
      variables: {
        eventId,
        eventAdminInviteId
      },
      refetchQueries: ['GetEventAdminsAndInvitees']
    })
      .then(() => {
        telemetry.adminInviteCancelSuccess(eventId, eventAdminInviteId);
        toast(translations.invitationCancelled, { icon: <CheckFilled /> });
        goToFirstStep();
      })
      .catch(() => {
        telemetry.adminInviteCancelFailed(eventId, eventAdminInviteId);
        toast(translations.errorCancellingInvitation);
      });
  };

  const handleRemoveAdmin = (eventId: string, auth0Id: string) => {
    removeAdmin({
      variables: {
        eventId,
        auth0Id
      },
      refetchQueries: ['GetEventAdminsAndInvitees']
    })
      .then(() => {
        telemetry.adminInviteRevokeSuccess(eventId, auth0Id);
        toast(translations.adminRemoved, { icon: <CheckFilled /> });
        goToFirstStep();
      })
      .catch(() => {
        telemetry.adminInviteRevokeFailed(eventId, auth0Id);
        toast(translations.errorRemovingAdmin);
      });
  };

  const emailVerificationUrl = useMemo(() => `/account/verify-email?prev=${encodeURIComponent(location.pathname + (location.search || '?dialog=verifyEmail'))}`, [location]);

  return (
    <Provider
      value={{
        closeInviteAdminDialog: handleClose,
        openInviteAdminDialog: handleOpen
      }}
    >
      {children}
      <InviteAdminDialog
        title={title}
        isOpen={dialogs.LIST_USERS.open}
        onClose={handleClose}
        onSave={handleClose}
        isLoading={isFetchingAdmins}
        owner={owner}
        collaborators={collaborators}
        invitations={invitees}
        onInviteCollaborator={handleInviteCollaborator}
        onResendInvitation={handleResendInvitation}
        onTransferOwnership={handleTransferEventOwnershipDialog}
        onRemoveCollaborator={handleRemoveCollaborator}
        onCancelInvitation={handleCancelInviteDialog}
        emailIsVerified={userProfile?.emailIsVerified}
        emailVerificationUrl={emailVerificationUrl}
        isDialogView={isDialogView}
      />
      <AddAdminDialog isOpen={dialogs.ADD_COLLABORATOR.open} onClose={goToFirstStep} onSave={handleSendInvitation} isLoading={invitingUser} />
      <RevokeAdminDialog
        isOpen={dialogs.REMOVE_COLLABORATOR.open}
        onClose={goToFirstStep}
        onRevoke={handleRevoke}
        fullname={dialogs.REMOVE_COLLABORATOR?.info?.name || dialogs.REMOVE_COLLABORATOR?.info?.email}
        isLoading={cancelingInvite || removingAdmin}
      />
      <TransferOwnershipDialog
        isOpen={dialogs.TRANSFER_OWNERSHIP.open}
        onClose={goToFirstStep}
        fullname={dialogs.TRANSFER_OWNERSHIP?.info?.name || dialogs.TRANSFER_OWNERSHIP?.info?.email}
        onSave={handleTransferEventOwnership}
        isLoading={transferingOwnership}
      />
    </Provider>
  );
};

InviteAdminDialogProvider.displayName = 'InviteAdminDialogProvider';

export { InviteAdminDialogProvider, useInviteAdminDialogContext };
