/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Mobile',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2.2H16C16.4418 2.2 16.8 2.55817 16.8 3V21C16.8 21.4418 16.4418 21.8 16 21.8H8C7.55817 21.8 7.2 21.4418 7.2 21V3C7.2 2.55817 7.55817 2.2 8 2.2ZM6 3C6 1.89543 6.89543 1 8 1H16C17.1046 1 18 1.89543 18 3V21C18 22.1046 17.1046 23 16 23H8C6.89543 23 6 22.1046 6 21V3ZM11.25 3C10.9739 3 10.75 3.22386 10.75 3.5C10.75 3.77614 10.9739 4 11.25 4H12.75C13.0261 4 13.25 3.77614 13.25 3.5C13.25 3.22386 13.0261 3 12.75 3H11.25Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6667 2.2H21.3333C22.1434 2.2 22.8 2.85665 22.8 3.66667V28.3333C22.8 29.1434 22.1434 29.8 21.3333 29.8H10.6667C9.85665 29.8 9.2 29.1434 9.2 28.3333V3.66667C9.2 2.85665 9.85665 2.2 10.6667 2.2ZM8 3.66667C8 2.19391 9.19391 1 10.6667 1H21.3333C22.8061 1 24 2.19391 24 3.66667V28.3333C24 29.8061 22.8061 31 21.3333 31H10.6667C9.19391 31 8 29.8061 8 28.3333V3.66667ZM14.5 4C14.2239 4 14 4.22386 14 4.5C14 4.77614 14.2239 5 14.5 5H17.5C17.7761 5 18 4.77614 18 4.5C18 4.22386 17.7761 4 17.5 4H14.5Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
