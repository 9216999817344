import React, { useEffect } from 'react';
import { Box, InputV2, TextV2, FormControl, Flex, CloseButton, SpacingStack } from '@withjoy/joykit';
import { useFormik } from 'formik';
import { useFieldErrors } from '@apps/createWedding/hooks/useFieldErrors';
import * as Yup from 'yup';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useTranslation } from '@shared/core';
import { closeButtonOverrideStyles, dialogOverrides, drawerOverridesStyles, inputOverrides, StyledActionButton } from '../Shared.styles';
import { pxToRem } from '@withjoy/joykit/theme';

interface AddAdminFields
  extends Readonly<{
    firstName: string;
    lastName: string;
    email: string;
  }> {}

export interface AddAdminProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (data: AddAdminFields) => void;
  isLoading?: boolean;
}

export const AddAdminDialog: React.FC<AddAdminProps> = ({ isOpen = true, onClose, isLoading, onSave }) => {
  const { t2 } = useTranslation('joykit');
  const translations = t2('inviteAdmin');
  const formik = useFormik<AddAdminFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: Yup.object<AddAdminFields>({
      firstName: Yup.string().required(translations.firstNameRequired).max(35),
      lastName: Yup.string().required(translations.lastNameRequired).max(35),
      email: Yup.string().email(translations.invalidEmail).required(translations.emailRequired).max(254)
    }),
    onSubmit: values => {
      if (onSave) {
        onSave(values);
      }
    }
  });
  const { handleChange, submitForm, values, dirty } = formik;
  const getFieldErrorProps = useFieldErrors<AddAdminFields>(formik);

  useEffect(() => {
    if (!isOpen && dirty) {
      formik.resetForm();
    }
  }, [isOpen, dirty, formik]);

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
      hideDivider={true}
      removeBodyPadding={true}
      dialogOverrides={dialogOverrides}
      drawerOverrides={drawerOverridesStyles}
    >
      <SpacingStack spacing={7}>
        <Box>
          <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
            {translations.addAdminTitle}
          </TextV2>
          <CloseButton overrides={closeButtonOverrideStyles} onClick={onClose} disabled={isLoading} aria-label={'close dialog'} />
        </Box>
        <FormControl {...getFieldErrorProps('firstName')} label={<TextV2 typographyVariant="label3">{translations.firstNamePlaceholder}</TextV2>} width="100%">
          <InputV2
            name="firstName"
            type="text"
            onChange={handleChange}
            value={values.firstName}
            overrides={inputOverrides}
            isDisabled={isLoading}
            aria-label={translations.firstNameAriaLabel}
            placeholder={translations.firstNamePlaceholder}
          />
        </FormControl>
        <FormControl {...getFieldErrorProps('lastName')} label={<TextV2 typographyVariant="label3">{translations.lastNamePlaceholder}</TextV2>} width="100%">
          <InputV2
            name="lastName"
            type="text"
            overrides={inputOverrides}
            onChange={handleChange}
            value={values.lastName}
            isDisabled={isLoading}
            aria-label={translations.lastNameAriaLabel}
            placeholder={translations.lastNamePlaceholder}
          />
        </FormControl>
        <FormControl {...getFieldErrorProps('email')} label={<TextV2 typographyVariant="label3">{translations.email}</TextV2>} width="100%">
          <Box>
            <InputV2
              name="email"
              type="email"
              overrides={inputOverrides}
              onChange={handleChange}
              value={values.email}
              isDisabled={isLoading}
              aria-label={translations.emailAriaLabel}
              placeholder={translations.emailPlaceholder}
            />
          </Box>
        </FormControl>
      </SpacingStack>
      <Flex flexDirection="column" width="100%" gap={5} marginTop={pxToRem(56)}>
        <StyledActionButton intent="neutral" onClick={submitForm} disabled={isLoading} loading={isLoading}>
          {translations.sendInvite}
        </StyledActionButton>
        <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={onClose} disabled={isLoading}>
          {translations.cancel}
        </StyledActionButton>
      </Flex>
    </ResponsiveDialog>
  );
};
