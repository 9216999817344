import { FormikErrors, FormikTouched } from 'formik';
import { useCallback } from 'react';

/**
 * We need to define FormikLike, because formik
 * does not share easily return type of useFormik :/
 */
type FormikLike<FormValues> = { initialValues: FormValues; errors: FormikErrors<FormValues>; touched: FormikTouched<FormValues> };

export const useFieldErrors = <FormValues extends Record<string, unknown>>(formikLike: FormikLike<FormValues>) => {
  return useCallback(
    (fieldName: keyof FormValues) => {
      const error = formikLike.touched[fieldName] ? formikLike.errors[fieldName] : undefined;

      return { error, isInvalid: !!error };
    },
    [formikLike]
  );
};
