import { createContext } from '@shared/utils/createContext';
import React, { Suspense as ReactSuspense, SuspenseProps, useCallback, useEffect, useState } from 'react';

const [SuspenseProvider, useContext] = createContext<boolean>({ name: 'SuspenseProvider', strict: false });

type FallbackWrapperProps = {
  onMount: () => void;
  onUnmount: () => void;
  children: SuspenseProps['fallback'];
};
const FallbackWrapper = ({ children, onMount, onUnmount }: FallbackWrapperProps) => {
  useEffect(() => {
    const mountTimeoutId = setTimeout(() => {
      onMount();
    });
    return () => {
      clearTimeout(mountTimeoutId);
      onUnmount();
    };
  }, [onMount, onUnmount]);

  return <>{children}</>;
};

/*
 * Suspense apparently renders a suspended tree before it resumes,
 * hiding it with `display: none !important;`.  This tweaked version lets child
 * components use the `useSuspended` hook to know if they're in a
 * rendered-while-suspended tree.
 *
 * https://github.com/facebook/react/issues/14536
 * https://github.com/mui/material-ui/issues/14077#issuecomment-861965259
 */
export const Suspense = ({ fallback, children }: SuspenseProps): React.ReactElement | null => {
  const [suspended, setSuspended] = useState(false);

  const onMount = useCallback(() => {
    setSuspended(true);
  }, []);
  const onUnmount = useCallback(() => {
    setSuspended(false);
  }, []);

  return (
    <ReactSuspense
      fallback={
        <FallbackWrapper onMount={onMount} onUnmount={onUnmount}>
          {fallback}
        </FallbackWrapper>
      }
    >
      <SuspenseProvider value={suspended}>{children}</SuspenseProvider>
    </ReactSuspense>
  );
};

export const useSuspended = () => {
  const parentSuspended = useContext();

  return parentSuspended ?? false;
};
