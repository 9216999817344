import { useMediaScreen } from '@shared/utils/media/useMediaScreens';
import { withWindow } from '@shared/utils/withWindow';
import { useHasActiveModals } from '@withjoy/joykit/components/Modal/modalManager';
import { useContext, useEffect, useRef } from 'react';
import { IntercomContext } from './context';
import { useNativeState } from '@shared/core/native';
import { MediaScreen } from '@shared/utils/media/DefaultMediaMatchProvider';
import globalWindow from '../globals';
import { addAction } from '@shared/utils/logger';
import { useMount } from '@shared/utils/hooks/useMount';

let usageCount = 0;

export const useIntercom = () => {
  const context = useContext(IntercomContext);

  useMount(() => {
    usageCount += 1;
    addAction('IntercomUsageCount', { usageCount, pathname: globalWindow.location?.pathname });
  });

  return context;
};

const INTERCOM_LAUNCHER_HTML_SELECTOR = '[role="button"].intercom-launcher';

const getLauncherNode = () => {
  return withWindow(global => {
    return global.document.querySelector(INTERCOM_LAUNCHER_HTML_SELECTOR);
  }, null);
};

type UseToggleIntercomLauncherOptions = {
  /**
   * Whether the launcher should be shown or hidden.
   */
  show: boolean;

  /**
   * Minimum screen size on which the Intercom launcher should be shown.
   *
   * @default "tablet"
   */
  minScreenSize?: MediaScreen;
};

type UseToggleIntercomLauncherWithUserInfoOptions = UseToggleIntercomLauncherOptions & {
  /**
   * Guest information for Intercom boot
   */
  userInfo?: {
    email?: string;
    name?: string;
  };
};

/**
 * Hook to show/hide the Intercom launcher. By default, it will show/hide depending on
 * the launcher's current visibility (is it shown or hidden). Intercom is only shown on
 * tablet/desktop sized devices.
 *
 * Upon hook unmount, it will reset the launcher's visibility back to its initial state.
 */
export const useToggleIntercomLauncher = ({ show, minScreenSize = 'tablet', userInfo }: UseToggleIntercomLauncherWithUserInfoOptions): void => {
  const meetsMinScreenSizeReq = useMediaScreen({ minScreenSize });
  const intercom = useIntercom();
  const { isNative } = useNativeState();
  const hasActiveModals = useHasActiveModals();
  const launcherIsShownInitially = useRef(Boolean(getLauncherNode()));

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (launcherIsShownInitially.current) {
        intercom.showLauncher();
      } else {
        intercom.hideLauncher();
      }
    };
  }, [intercom]);

  useEffect(() => {
    if (show && meetsMinScreenSizeReq && !(hasActiveModals || isNative)) {
      intercom.showLauncher();
      if (userInfo?.name || userInfo?.email) {
        intercom.identify({
          ...(userInfo.name && { name: userInfo.name }),
          ...(userInfo.email && { email: userInfo.email })
        });
      }
    } else {
      intercom.hideLauncher();
    }
  }, [isNative, show, intercom, meetsMinScreenSizeReq, hasActiveModals, userInfo]);
};
