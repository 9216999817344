import React, { useContext } from 'react';

import { ComponentHealthWrapper } from './ComponentHealthWrapper/ComponentHealthWrapper';
import { InternalContextValue } from './types';

export type ComponentHealthWrapFn = <T extends {}>(component: React.ComponentType<T>) => React.FunctionComponent<T & { componentHealthNameOverride?: string }>;

export interface ComponentHealthCallbacks {
  readonly onLoadComplete: () => void;
  readonly onLoadFailure: (reason: unknown, context?: object) => void;
}

export function createComponentHealth(name: string): [wrapWithComponentHealth: ComponentHealthWrapFn, useComponentHealth: () => ComponentHealthCallbacks] {
  const context = React.createContext<InternalContextValue | undefined>(undefined);

  return [
    <T extends { componentHealthNameOverride?: string }>(WrappedComponent: React.ComponentType<T>) => {
      return ({ componentHealthNameOverride, ...restProps }: T) => (
        <ComponentHealthWrapper name={componentHealthNameOverride || name} internalContext={context}>
          <WrappedComponent {...(restProps as T)} />
        </ComponentHealthWrapper>
      );
    },
    () => {
      const contextValue = useContext(context);

      if (!contextValue) {
        throw new Error(`useComponentHealth must be used in a component wrapped with wrapWithComponentHealth for '${name}'!`);
      }

      const { onLoadComplete, onLoadFailure, onRegisterWithProvider } = contextValue;

      onRegisterWithProvider();

      return {
        onLoadComplete,
        onLoadFailure
      };
    }
  ];
}
