import React from 'react';
import { Box, CloseButton, Flex, InputV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useTranslation } from '@shared/core';
import { closeButtonOverrideStyles, drawerOverridesStyles, StyledActionButton } from '../Shared.styles';
import { pxToRem } from '@withjoy/joykit/theme';

const confirmationWord = 'TRANSFER';

export interface TransferOwnershipDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
  fullname: Maybe<string>;
  isLoading?: boolean;
}

export const TransferOwnershipDialog: React.FC<TransferOwnershipDialogProps> = ({ isOpen = true, onClose, fullname, isLoading, onSave }) => {
  const [transferInput, setTransferInput] = React.useState<string>('');
  const { t } = useTranslation('joykit');
  const translations = t('inviteAdmin');

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
      hideDivider={true}
      removeBodyPadding={true}
      dialogOverrides={{
        Content: { props: { width: ['100%', '480px'] } },
        Body: { props: { paddingX: 8, paddingY: 8 } }
      }}
      drawerOverrides={drawerOverridesStyles}
    >
      <Flex width="100%" justifyContent="center">
        <Flex flexDirection="column" width="100%" maxWidth={pxToRem(320)}>
          <SpacingStack spacing={6} maxWidth={pxToRem(320)}>
            <Box>
              <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
                {translations.transferOwnershipTitle()}
              </TextV2>
              <CloseButton overrides={closeButtonOverrideStyles} onClick={onClose} disabled={isLoading} aria-label={'close dialog'} />
            </Box>
            <TextV2 typographyVariant="body2" color="mono12">
              {translations.transferOwnershipMessage({
                fullname: (
                  <TextV2 as="span" typographyVariant="body2" color="mono14">
                    {fullname}
                  </TextV2>
                )
              })}
            </TextV2>
            <TextV2 typographyVariant="body2" color="mono12">
              {translations.transferOwnershipConfirmation()}
            </TextV2>
            <Flex flexDirection="column" gap={3}>
              <TextV2 typographyVariant="label3" color="mono14">
                {translations.transferOwnershipInputLabel({
                  confirmationWord
                })}
              </TextV2>
              <InputV2
                placeholder={confirmationWord}
                onChange={e => {
                  setTransferInput(e.target.value);
                }}
                isDisabled={isLoading}
                maxLength={confirmationWord.length}
                aria-label="confirmation input"
              />
            </Flex>
          </SpacingStack>
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="100%" gap={5} marginTop={8}>
        <StyledActionButton intent="destructive" onClick={onSave} disabled={isLoading || confirmationWord !== transferInput} loading={isLoading}>
          {translations.transferAccount()}
        </StyledActionButton>
        <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={onClose} disabled={isLoading}>
          {translations.cancel()}
        </StyledActionButton>
      </Flex>
    </ResponsiveDialog>
  );
};
