import { styled, StyleSystemProps, Box, BoxProps } from '@withjoy/joykit';
import { keyframes } from '@withjoy/joykit/theme';
import { animationTransition, STANDARD_TIMING_FUNCTION } from '@shared/utils/animationTransition';

const growToWidth = keyframes`
from { width: 0%; }
to { width: 100%; }
`;

export const StyledProgressBar = styled(Box)``;
const progressBarStyles = (height: number, backgroundColor: BoxProps['color'] = 'mono4'): StyleSystemProps => ({
  backgroundColor,
  width: '100%',
  height,
  borderRadius: 3,
  position: 'relative'
});

export const StyledProgressBarFill = styled(Box)`
  ::after {
    // This handles the animation of initial render
    animation: ${growToWidth} 1.5s ${STANDARD_TIMING_FUNCTION} forwards 1s;
  }
`;
const progressBarFillStyles = (
  height: number,
  backgroundColor: BoxProps['color'] = 'mono14', // this is the color of the progress bar
  gradientFill?: string
): StyleSystemProps => ({
  position: 'absolute',
  left: 0,
  top: 0,
  maxWidth: '100%',
  width: 0,
  minWidth: height,
  // this transition handles the animation of width change
  transition: animationTransition('width'),
  _after: {
    content: '""',
    display: 'block',
    backgroundColor,
    height,
    borderRadius: 'full',
    width: 0,
    backgroundImage: gradientFill || 'none'
  }
});

export const styles = {
  progressBar: progressBarStyles,
  progressBarFill: progressBarFillStyles
};
