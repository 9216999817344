import React from 'react';
import { Box, CloseButton, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useTranslation } from '@shared/core';
import { closeButtonOverrideStyles, drawerOverridesStyles, StyledActionButton } from '../Shared.styles';

export interface RevokeAdminDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onRevoke?: () => void;
  fullname: Maybe<string>;
  isLoading?: boolean;
}

export const RevokeAdminDialog: React.FC<RevokeAdminDialogProps> = ({ isOpen = true, onClose, fullname, isLoading, onRevoke }) => {
  const { t } = useTranslation('joykit');
  const translations = t('inviteAdmin');

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
      hideDivider={true}
      removeBodyPadding={true}
      dialogOverrides={{
        Content: { props: { width: ['100%', '480px'] } },
        Body: { props: { paddingX: 8, paddingY: 8 } }
      }}
      drawerOverrides={drawerOverridesStyles}
    >
      <SpacingStack spacing={6}>
        <Box>
          <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
            {translations.revokeAdminInvitationTitle()}
          </TextV2>
          <CloseButton overrides={closeButtonOverrideStyles} onClick={onClose} disabled={isLoading} aria-label={'close dialog'} />
        </Box>
        <TextV2 typographyVariant="body2" color="mono12" textAlign="center">
          {translations.revokeAdminMessage({
            fullname: (
              <TextV2 as="span" typographyVariant="body2" color="mono14">
                {fullname}’s
              </TextV2>
            )
          })}
        </TextV2>
      </SpacingStack>
      <Flex flexDirection="column" width="100%" gap={5} marginTop={8}>
        <StyledActionButton intent="destructive" onClick={onRevoke} disabled={isLoading} loading={isLoading}>
          {translations.revoke()}
        </StyledActionButton>
        <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={onClose} disabled={isLoading}>
          {translations.cancel()}
        </StyledActionButton>
      </Flex>
    </ResponsiveDialog>
  );
};
