import { ProgressBar } from '@shared/components/ProgressBar';
import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex, PublicBoxProps, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React from 'react';
import { ChecklistCardContainer, styles } from '../../DashboardChecklist.styles';
import { FormattedCurrency } from '@shared/utils/currency';
import { SkeletonThumbnail } from '@shared/components/Skeleton';
import { EventPageVisibility } from '@graphql/generated';
import { LockFilled, Moon, Sun } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';

interface DashboardStatProps extends PublicBoxProps {
  children: React.ReactNode;
}

interface DashboardStatHeaderProps extends PublicBoxProps {
  title: string;
  extraInfo?: EventPageVisibility;
}

interface DashboardStatItemProps extends PublicBoxProps {
  link?: string;
}

interface DashboardStatItemDefaultProps extends DashboardStatItemProps {
  value: number;
  color: string;
  icon: React.ReactNode;
  cta: string;
  ctaHover: string;
}

interface DashboardStatItemProgressProps extends DashboardStatItemDefaultProps {
  total: number;
  goalLabel: string;
  currencyCode?: string;
  currencyPlaces?: number;
}

const DashboardStat = (props: DashboardStatProps) => {
  return <ChecklistCardContainer className={'dashboard-stat'} __css={styles.checklistCardContainer} {...props} />;
};

const DashboardStatSkeleton = () => {
  return (
    <ChecklistCardContainer __css={styles.checklistCardContainer}>
      <Flex paddingX={5} height={8} color={'mono12'} alignItems={'center'} width={'100%'}>
        <SkeletonThumbnail width={pxToRem(90)} height={pxToRem(21)} />
      </Flex>
      <DashboardStatRow>
        <DashboardStatItem>
          <SkeletonThumbnail width={pxToRem(45)} height={pxToRem(45)} />
        </DashboardStatItem>
        <DashboardStatItem>
          <SkeletonThumbnail width={pxToRem(45)} height={pxToRem(45)} />
        </DashboardStatItem>
      </DashboardStatRow>
    </ChecklistCardContainer>
  );
};

const DashboardStatHeader = ({ title, extraInfo, ...props }: DashboardStatHeaderProps) => {
  const { t2 } = useTranslation('adminDashboardV2');
  const { hidden, passwordProtected, public: publicTranslation } = t2('visibility');

  return (
    <Flex paddingX={5} height={8} color={'mono12'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} {...props}>
      <TextV2 typographyVariant={'hed1'}>{title}</TextV2>
      {extraInfo && (
        <Flex columnGap={2} alignItems={'center'} justifyContent={'center'}>
          {extraInfo === EventPageVisibility.hidden && (
            <>
              <Moon size={'sm'} />
              <TextV2 typographyVariant={'label1'}>{hidden}</TextV2>
            </>
          )}
          {extraInfo === EventPageVisibility.passwordProtected && (
            <>
              <LockFilled size={'sm'} />
              <TextV2 typographyVariant={'label1'}>{passwordProtected}</TextV2>
            </>
          )}
          {extraInfo === EventPageVisibility.public && (
            <>
              <Sun size={'sm'} />
              <TextV2 typographyVariant={'label1'}>{publicTranslation}</TextV2>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const DashboardStatRow = (props: DashboardStatProps) => {
  return <Flex borderTop="1px solid" borderColor={'mono2'} {...props} />;
};

const DashboardStatItem = (props: DashboardStatItemProps) => {
  return (
    <Box
      padding={5}
      borderRight={'1px solid'}
      borderColor={'mono2'}
      flexGrow={1}
      _lastChild={{
        borderRight: 'none'
      }}
      role={'group'}
      transition={animationTransition('background-color')}
      flexBasis={'100%'}
      cursor={'pointer'}
      _hover={{
        backgroundColor: 'mono2'
      }}
      _active={{
        backgroundColor: 'mono3'
      }}
      {...(props.link && {
        as: 'a',
        href: props.link
      })}
      {...props}
    />
  );
};

export const DashboardStatItemDefault = ({ value, color, icon, cta, ctaHover, link, ...props }: DashboardStatItemDefaultProps) => {
  return (
    <DashboardStatItem link={link} {...props}>
      <Flex justifyContent={'space-between'}>
        <TextV2
          fontSize={value >= 999 ? pxToRem(26) : pxToRem(40)}
          color={color}
          lineHeight={'none'}
          transition={animationTransition('color')}
          _groupHover={{
            color: 'linkHover'
          }}
        >
          {value}
        </TextV2>
        {value <= 999 && <Box color={'mono10'}>{icon}</Box>}
      </Flex>
      <Box marginTop={1} position={'relative'}>
        <TextV2
          fontSize={'300'}
          color={'mono12'}
          transition={animationTransition('color', 'opacity', 'transform')}
          opacity={1}
          _groupHover={{
            color: 'linkHover',
            opacity: 0,
            transform: 'translateY(-20px)'
          }}
        >
          {cta}
        </TextV2>
        <TextV2
          fontSize={'300'}
          color={'mono12'}
          transition={animationTransition('color', 'opacity', 'transform')}
          opacity={0}
          _groupHover={{
            color: 'linkHover',
            opacity: 1,
            transform: 'translateY(0)'
          }}
          position={'absolute'}
          top={0}
          transform={'translateY(20px)'}
        >
          {ctaHover}
        </TextV2>
      </Box>
    </DashboardStatItem>
  );
};

const DashboardStatItemProgress = ({ color, value = 0, total = 0, icon, cta, ctaHover, goalLabel, currencyCode, currencyPlaces, link }: DashboardStatItemProgressProps) => {
  return (
    <DashboardStatItem link={link}>
      <Flex justifyContent={'space-between'} alignItems={'center'} paddingBottom={3}>
        <TextV2
          fontSize={'900'}
          color={color}
          lineHeight={'none'}
          transition={animationTransition('color')}
          _groupHover={{
            color: 'linkHover'
          }}
        >
          <FormattedCurrency
            formatForm="short"
            priceFloatingPointDecimalString={(value / 100).toFixed(currencyPlaces).toString()}
            priceCurrencyCode={currencyCode}
            formatPrecision={0}
          />
        </TextV2>
        <Flex justifyContent={'center'} alignItems={'center'} columnGap={3} color={'mono10'}>
          <TextV2>
            <FormattedCurrency
              formatForm="short"
              priceFloatingPointDecimalString={(total / 100).toFixed(currencyPlaces).toString()}
              priceCurrencyCode={currencyCode}
              formatPrecision={0}
            />
          </TextV2>
          {icon}
        </Flex>
      </Flex>
      <ProgressBar height={3} progressPercentage={(value / total) * 100} backgroundColor={'mono4'} fillColor={color} />
      <Box position={'relative'}>
        <Flex
          justifyContent={'space-between'}
          transition={animationTransition('color', 'opacity', 'transform')}
          opacity={1}
          _groupHover={{
            color: 'linkHover',
            opacity: 0,
            transform: 'translateY(-20px)'
          }}
          paddingTop={3}
        >
          <TextV2 fontSize={'300'} color={'mono14'}>
            {cta}
          </TextV2>
          <TextV2 fontSize={'300'} color={'mono10'}>
            {goalLabel}
          </TextV2>
        </Flex>
        <TextV2
          fontSize={'300'}
          color={'mono12'}
          transition={animationTransition('color', 'opacity', 'transform')}
          opacity={0}
          _groupHover={{
            color: 'linkHover',
            opacity: 1,
            transform: 'translateY(0)'
          }}
          position={'absolute'}
          top={0}
          transform={'translateY(20px)'}
          paddingTop={3}
          width={'100%'}
          textAlign={'center'}
        >
          {ctaHover}
        </TextV2>
      </Box>
    </DashboardStatItem>
  );
};

DashboardStat.Skeleton = DashboardStatSkeleton;
DashboardStat.Header = DashboardStatHeader;
DashboardStat.Row = DashboardStatRow;
DashboardStat.ItemDefault = DashboardStatItemDefault;
DashboardStat.ItemProgress = DashboardStatItemProgress;

export { DashboardStat };
