import { Flex, styled } from '@withjoy/joykit';

export const UserGravatar = styled(Flex)`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  min-width: 2.5rem;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
