/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Open In New Window',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M13.9 3.3V5.7C13.9 6.00376 14.1462 6.25 14.45 6.25C14.7538 6.25 15 6.00376 15 5.7V2C15 1.44772 14.5523 1 14 1H10.3C9.99624 1 9.75 1.24624 9.75 1.55C9.75 1.85376 9.99624 2.1 10.3 2.1H12.7C12.8701 2.1 12.9552 2.3056 12.835 2.42584L9.50485 5.75596C9.29006 5.97075 9.29006 6.31899 9.50485 6.53378C9.71964 6.74856 10.0679 6.74856 10.2827 6.53377L13.6288 3.18766C13.7289 3.08758 13.9 3.15846 13.9 3.3Z"
            fill="currentColor"
          />{' '}
          <path
            d="M12.7 8.875C12.3962 8.875 12.15 9.12124 12.15 9.425V13C12.15 13.4971 11.7471 13.9 11.25 13.9H3C2.50294 13.9 2.1 13.4971 2.1 13V4.75C2.1 4.25294 2.50294 3.85 3 3.85H6.575C6.87876 3.85 7.125 3.60376 7.125 3.3C7.125 2.99624 6.87876 2.75 6.575 2.75H3C1.89543 2.75 1 3.64543 1 4.75V13C1 14.1046 1.89543 15 3 15H11.25C12.3546 15 13.25 14.1046 13.25 13V9.425C13.25 9.12124 13.0038 8.875 12.7 8.875Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 4C19.1046 4 20 4.89543 20 6V9.4C20 9.73137 19.7314 10 19.4 10C19.0686 10 18.8 9.73137 18.8 9.4V6.05227L14.9467 9.90553C14.7124 10.1398 14.3325 10.1398 14.0982 9.90553C13.8639 9.67121 13.8639 9.29131 14.0982 9.057L17.9552 5.2H14.6C14.2686 5.2 14 4.93137 14 4.6C14 4.26863 14.2686 4 14.6 4H18ZM6 7.2C5.55817 7.2 5.2 7.55817 5.2 8V18C5.2 18.4418 5.55817 18.8 6 18.8H16C16.4418 18.8 16.8 18.4418 16.8 18V13.6C16.8 13.2686 17.0686 13 17.4 13C17.7314 13 18 13.2686 18 13.6V18C18 19.1046 17.1046 20 16 20H6C4.89543 20 4 19.1046 4 18V8C4 6.89543 4.89543 6 6 6H10.4C10.7314 6 11 6.26863 11 6.6C11 6.93137 10.7314 7.2 10.4 7.2H6Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
