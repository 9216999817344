import { useMemo } from 'react';
import { useQueryParamHelper } from '@shared/core/queryString';

export const useExtractFeatureFlagParams = (featureKeys: string[]): Record<string, string> => {
  const queryParamHelper = useQueryParamHelper();

  const featureFlagParams = useMemo(() => {
    return featureKeys.reduce((acc, key) => {
      const value = queryParamHelper.getValue(key);
      if (value) {
        acc[key] = value as string;
      }
      return acc;
    }, {} as Record<string, string>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParamHelper]);

  return featureFlagParams;
};
