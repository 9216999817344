/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Notification Bubble',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M3.828 1.391h.001 8.342a2.59 2.59 0 0 1 1.834.772 2.64 2.64 0 0 1 .759 1.848v.001 5.106a2.64 2.64 0 0 1-.757 1.853 2.59 2.59 0 0 1-1.837.774h-.001-2.021L8.487 14.37c-.094.149-.259.24-.435.24s-.341-.09-.436-.239l-1.671-2.631H3.83h-.001c-.689-.002-1.349-.28-1.834-.772a2.64 2.64 0 0 1-.759-1.848v-.001-5.107a2.64 2.64 0 0 1 .759-1.848 2.59 2.59 0 0 1 1.834-.772zm.002 1.031c-.412.001-.809.168-1.103.465a1.61 1.61 0 0 0-.462 1.126v5.104a1.61 1.61 0 0 0 .462 1.126c.294.297.69.464 1.103.465h2.398c.176 0 .341.09.435.239l1.387 2.184 1.379-2.178c.095-.149.259-.24.436-.24h2.304c.413-.001.81-.168 1.104-.467a1.61 1.61 0 0 0 .461-1.129V4.013a1.61 1.61 0 0 0-.462-1.126c-.294-.297-.69-.464-1.103-.465H3.83z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.993 2.85h.002 12.013c.954.003 1.868.388 2.54 1.069A3.66 3.66 0 0 1 21.6 6.48v.002 7.352a3.66 3.66 0 0 1-1.049 2.568c-.673.683-1.587 1.07-2.544 1.072h-.002-3.264l-2.161 3.397a.6.6 0 0 1-1.012.001l-2.174-3.406h-3.4-.002c-.955-.003-1.868-.388-2.54-1.069A3.66 3.66 0 0 1 2.4 13.836v-.002V6.48a3.66 3.66 0 0 1 1.053-2.562c.673-.681 1.586-1.066 2.54-1.069zm.003 1.2c-.632.002-1.24.257-1.689.712A2.46 2.46 0 0 0 3.6 6.483v7.351a2.46 2.46 0 0 0 .706 1.721c.449.455 1.057.71 1.689.712h3.727a.6.6 0 0 1 .506.277l1.844 2.889 1.833-2.88a.6.6 0 0 1 .506-.278h3.592c.633-.002 1.242-.258 1.692-.714a2.46 2.46 0 0 0 .704-1.726V6.484a2.46 2.46 0 0 0-.706-1.722c-.449-.455-1.057-.71-1.689-.712H5.995z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M7.991 4h.002 16.016a4.58 4.58 0 0 1 3.245 1.366A4.68 4.68 0 0 1 28.6 8.641v.002 9.803a4.68 4.68 0 0 1-1.341 3.284 4.58 4.58 0 0 1-3.25 1.37h-.002-4.462l-2.941 4.622a.6.6 0 0 1-1.012.001l-2.958-4.634H7.993h-.002a4.58 4.58 0 0 1-3.245-1.366A4.68 4.68 0 0 1 3.4 18.448v-.002-9.803-.002a4.68 4.68 0 0 1 1.346-3.276A4.58 4.58 0 0 1 7.991 4zm.003 1.2A3.38 3.38 0 0 0 5.6 6.209a3.48 3.48 0 0 0-1 2.435v9.801a3.48 3.48 0 0 0 1 2.435 3.38 3.38 0 0 0 2.394 1.009h4.97a.6.6 0 0 1 .506.277l2.628 4.117 2.612-4.105a.6.6 0 0 1 .506-.278h4.79a3.38 3.38 0 0 0 2.398-1.012 3.48 3.48 0 0 0 .996-2.442V8.645a3.48 3.48 0 0 0-.999-2.436A3.38 3.38 0 0 0 24.006 5.2H7.994z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M11.987 6.725h.002 24.024c1.755.005 3.435.713 4.673 1.967s1.934 2.951 1.939 4.721v.002 14.705c0 1.773-.694 3.475-1.933 4.733s-2.922 1.969-4.679 1.974h-.002-7.471l-3.863 6.157c-.114.182-.314.293-.529.293s-.415-.11-.529-.292l-3.879-6.174h-7.75-.002c-1.755-.005-3.435-.713-4.673-1.967S5.38 29.89 5.375 28.121v-.002-14.705-.002c.005-1.77.701-3.467 1.939-4.721s2.918-1.962 4.673-1.967zm.003 1.25c-1.419.004-2.78.577-3.786 1.595s-1.575 2.401-1.579 3.845v14.703c.004 1.444.573 2.826 1.579 3.845s2.367 1.591 3.786 1.595h8.095c.215 0 .415.11.529.292l3.533 5.623 3.517-5.606c.114-.182.314-.293.529-.293h7.815c1.422-.004 2.786-.579 3.792-1.601s1.574-2.409 1.574-3.856V13.416c-.004-1.444-.572-2.827-1.579-3.846s-2.367-1.591-3.786-1.595H11.99z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.316 8.125h.002 33.366a9.04 9.04 0 0 1 6.407 2.697 9.25 9.25 0 0 1 2.659 6.474v.002 20.423a9.25 9.25 0 0 1-2.651 6.49 9.04 9.04 0 0 1-6.416 2.706H38.24l-5.399 8.606a.75.75 0 0 1-1.27 0l-5.422-8.63h-10.83-.002a9.04 9.04 0 0 1-6.407-2.697 9.25 9.25 0 0 1-2.659-6.474v-.002-20.423-.002a9.25 9.25 0 0 1 2.659-6.474 9.04 9.04 0 0 1 6.407-2.697zm.003 1.5a7.54 7.54 0 0 0-5.343 2.251 7.75 7.75 0 0 0-2.227 5.423V37.72a7.75 7.75 0 0 0 2.227 5.423 7.54 7.54 0 0 0 5.342 2.251h11.244a.75.75 0 0 1 .635.351l5.007 7.969 4.985-7.946a.75.75 0 0 1 .635-.351H48.68a7.54 7.54 0 0 0 5.351-2.258 7.75 7.75 0 0 0 2.219-5.438V17.3a7.75 7.75 0 0 0-2.227-5.424 7.54 7.54 0 0 0-5.343-2.251H15.319z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
