/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chat',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M4.521 10.683v2.573l2.594-2.089h2.24c2.51 0 4.544-2.035 4.544-4.544s-2.035-4.544-4.544-4.544H6.644c-2.51 0-4.544 2.035-4.544 4.544 0 1.544.769 2.909 1.95 3.732l.471.328zM1 6.623C1 3.506 3.527.979 6.644.979h2.711C12.473.979 15 3.506 15 6.623s-2.527 5.644-5.644 5.644H7.503l-3.284 2.644a.49.49 0 0 1-.798-.382v-3.272C1.958 10.238 1 8.542 1 6.623z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M6.659 16.145v4.024l3.918-3.154h3.36c3.791 0 6.864-3.073 6.864-6.863s-3.073-6.863-6.863-6.863h-3.873C6.273 3.287 3.2 6.36 3.2 10.151c0 2.332 1.162 4.393 2.945 5.636l.514.358zM2 10.151c0-4.453 3.61-8.063 8.063-8.063h3.873c4.453 0 8.063 3.61 8.063 8.063s-3.61 8.064-8.063 8.064h-2.937l-4.4 3.542a.7.7 0 0 1-1.14-.546v-4.44C3.368 15.314 2 12.892 2 10.151z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M8.696 21.576v5.795l5.58-4.492h4.241c5.127 0 9.283-4.156 9.283-9.283s-4.156-9.283-9.283-9.283h-5.035c-5.127 0-9.282 4.156-9.282 9.283a9.27 9.27 0 0 0 3.982 7.622l.514.358zM3 13.596C3 7.806 7.693 3.113 13.483 3.113h5.035C24.307 3.113 29 7.806 29 13.596s-4.693 10.483-10.483 10.483h-3.818l-5.72 4.605c-.596.48-1.482.056-1.482-.71v-5.772A10.47 10.47 0 0 1 3 13.596z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M12.167 33.188v8.935c0 .046.011.068.02.082s.034.039.066.055.062.019.084.017c.017-.002.041-.007.077-.036l8.402-6.764h7.057A14.88 14.88 0 0 0 42.75 20.6 14.88 14.88 0 0 0 27.873 5.723h-7.746A14.88 14.88 0 0 0 5.25 20.6c0 5.057 2.522 9.525 6.382 12.215l.535.373zM4 20.6A16.13 16.13 0 0 1 20.127 4.473h7.746A16.13 16.13 0 0 1 44 20.6a16.13 16.13 0 0 1-16.127 16.127h-6.617l-8.058 6.487c-.917.738-2.281.086-2.281-1.092V33.84C6.736 30.927 4 26.083 4 20.6z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M15.852 44.591v11.778a.38.38 0 0 0 .616.295l11.055-8.899h9.706c11.188 0 20.258-9.07 20.258-20.258S48.417 7.249 37.229 7.249H26.771c-11.188 0-20.258 9.07-20.258 20.257 0 6.886 3.434 12.97 8.69 16.633l.648.452zM5 27.507C5 15.483 14.747 5.735 26.771 5.735h10.457C49.253 5.735 59 15.483 59 27.507s-9.747 21.771-21.771 21.771h-9.172l-10.639 8.565c-1.238.997-3.079.115-3.079-1.474V45.381C8.694 41.448 5 34.909 5 27.507z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
