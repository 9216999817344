import { useTranslation } from '@shared/core';
import { animationTransition } from '@shared/joykit/packages/core/utils';
import { useOuterClick } from '@shared/utils/hooks/useOuterClick';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { AddToCalendar } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';

interface ChecklistReminderButtonProps {
  reminderAt?: Maybe<string>;
  onChangeReminderDate: (date: string) => void;
  variant?: 'default' | 'iconButton';
}

export const ChecklistReminderButton: React.FC<ChecklistReminderButtonProps> = ({ reminderAt, onChangeReminderDate, variant = 'default' }) => {
  const { t2 } = useTranslation('adminDashboardV2');
  const { noDate, changeDate } = t2('checklist', 'reminderButton');

  const [pickerOpen, setPickerOpen] = useState(false);

  const dateInputRef = useRef<HTMLInputElement | null>(null);

  const handleCloseDateInput = useCallback(() => {
    dateInputRef.current?.blur();
    setPickerOpen(false);
  }, []);
  const buttonContainerRef = useOuterClick(handleCloseDateInput, pickerOpen);

  const handleButtonClick = useCallback(
    e => {
      e.stopPropagation();
      if (!pickerOpen) {
        dateInputRef.current?.showPicker();
        dateInputRef.current?.focus();
      } else {
        dateInputRef.current?.blur();
      }
      setPickerOpen(active => !active);
    },
    [pickerOpen]
  );

  const formattedDate = useMemo(() => {
    if (!reminderAt) return null;
    const dateObj = new Date(reminderAt.slice(0, -1));
    return format(dateObj, 'MMM d');
  }, [reminderAt]);

  const renderDateInput = useCallback(() => {
    return (
      <Box
        as="input"
        type="date"
        ref={dateInputRef}
        width={0}
        opacity={0}
        position={'absolute'}
        onBlur={e => {
          setPickerOpen(false);
          onChangeReminderDate(e.target.value);
        }}
        min={new Date().toISOString().split('T')[0]}
        pointerEvents={'none'}
      />
    );
  }, [dateInputRef, onChangeReminderDate]);

  const renderPlanButton = useCallback(() => {
    return (
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        columnGap={1}
        backgroundColor={variant === 'default' ? 'mono1' : 'mono3'}
        paddingRight={4}
        paddingLeft={3}
        height={7}
        borderRadius={5}
        color={pickerOpen ? 'linkActive' : 'mono10'}
        opacity={variant === 'default' ? 1 : pickerOpen ? 1 : 0}
        whiteSpace={'nowrap'}
        _groupHover={{
          backgroundColor: 'mono2',
          color: pickerOpen ? 'linkActive' : 'linkHover',
          opacity: 1
        }}
        transition={animationTransition('color', 'opacity', 'background-color')}
      >
        <AddToCalendar size={'sm'} />
        <TextV2 typographyVariant={'label1'}>{noDate}</TextV2>
        {renderDateInput()}
      </Flex>
    );
  }, [noDate, pickerOpen, renderDateInput, variant]);

  if (reminderAt) {
    return (
      <Flex
        ref={buttonContainerRef}
        alignItems={'center'}
        justifyContent={'center'}
        columnGap={1}
        backgroundColor={'white'}
        paddingX={4}
        height={7}
        borderRadius={5}
        color={'mono10'}
        whiteSpace={'nowrap'}
        maxWidth={70}
        _hover={{
          color: 'linkHover',
          backgroundColor: 'mono2',
          maxWidth: pxToRem(110)
        }}
        _active={{
          color: 'linkActive'
        }}
        transition={animationTransition('color', 'background-color', 'max-width')}
        role="group"
        onClick={handleButtonClick}
        cursor={'pointer'}
      >
        <TextV2
          typographyVariant={'label1'}
          display={'inline'}
          _groupHover={{
            display: 'none'
          }}
        >
          {formattedDate}
        </TextV2>
        <TextV2
          typographyVariant={'label1'}
          display={'none'}
          opacity={0}
          _groupHover={{
            display: 'inline',
            opacity: 1
          }}
          transition={animationTransition('opacity')}
        >
          {changeDate}
        </TextV2>
        {renderDateInput()}
      </Flex>
    );
  }

  if (variant === 'iconButton') {
    return (
      <Flex
        ref={buttonContainerRef}
        height={7}
        maxWidth={pickerOpen ? pxToRem(100) : 7}
        transition={animationTransition('max-width')}
        overflow={pickerOpen ? 'visible' : 'hidden'}
        _hover={{
          maxWidth: pxToRem(100),
          overflow: 'visible'
        }}
        role="group"
        onClick={handleButtonClick}
        cursor={'pointer'}
      >
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          backgroundColor={'mono2'}
          width={7}
          height={7}
          borderRadius={5}
          color={'mono10'}
          position={'absolute'}
          opacity={pickerOpen ? 0 : 1}
          transition={animationTransition('opacity')}
          _groupHover={{
            opacity: 0
          }}
        >
          <AddToCalendar size={'sm'} />
        </Flex>
        {renderPlanButton()}
      </Flex>
    );
  }

  return (
    <Flex ref={buttonContainerRef} height={7} role="group" onClick={handleButtonClick} cursor={'pointer'}>
      {renderPlanButton()}
    </Flex>
  );
};
