/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Clock',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M8.54995 4.32335C8.54995 4.01959 8.30371 3.77335 7.99995 3.77335C7.69619 3.77335 7.44995 4.01959 7.44995 4.32335V8.46112L9.56602 10.5772C9.78081 10.792 10.1291 10.792 10.3438 10.5772C10.5586 10.3624 10.5586 10.0142 10.3438 9.79938L8.54995 8.00549V4.32335Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99995 0.949997C4.10634 0.949997 0.949951 4.10639 0.949951 8C0.949951 11.8936 4.10634 15.05 7.99995 15.05C11.8936 15.05 15.05 11.8936 15.05 8C15.05 4.10639 11.8936 0.949997 7.99995 0.949997ZM2.04995 8C2.04995 4.7139 4.71386 2.05 7.99995 2.05C11.286 2.05 13.95 4.7139 13.95 8C13.95 11.2861 11.286 13.95 7.99995 13.95C4.71386 13.95 2.04995 11.2861 2.04995 8Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8 12c0 5.412-4.388 9.8-9.8 9.8-5.412 0-9.8-4.388-9.8-9.8 0-5.412 4.388-9.8 9.8-9.8 5.412 0 9.8 4.388 9.8 9.8zM12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm.6-17.222a.6.6 0 1 0-1.2 0v6.865l.176.176 3.308 3.309a.6.6 0 1 0 .849-.849L12.6 12.146V5.778z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M16.6002 7.70406C16.6002 7.37269 16.3315 7.10406 16.0002 7.10406C15.6688 7.10406 15.4002 7.37269 15.4002 7.70406V16.7751L19.9871 21.362C20.2215 21.5964 20.6014 21.5964 20.8357 21.362C21.07 21.1277 21.07 20.7478 20.8357 20.5135L16.6002 16.278V7.70406Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0002 30.6667C24.1003 30.6667 30.6668 24.1002 30.6668 16C30.6668 7.89987 24.1003 1.33337 16.0002 1.33337C7.89999 1.33337 1.3335 7.89987 1.3335 16C1.3335 24.1002 7.89999 30.6667 16.0002 30.6667ZM29.4668 16C29.4668 23.4375 23.4376 29.4667 16.0002 29.4667C8.56273 29.4667 2.5335 23.4375 2.5335 16C2.5335 8.56261 8.56273 2.53337 16.0002 2.53337C23.4376 2.53337 29.4668 8.56261 29.4668 16Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.625 24C5.625 13.8518 13.8518 5.625 24 5.625C34.1482 5.625 42.375 13.8518 42.375 24C42.375 34.1482 34.1482 42.375 24 42.375C13.8518 42.375 5.625 34.1482 5.625 24ZM24 4.375C13.1614 4.375 4.375 13.1614 4.375 24C4.375 34.8386 13.1614 43.625 24 43.625C34.8386 43.625 43.625 34.8386 43.625 24C43.625 13.1614 34.8386 4.375 24 4.375ZM24.625 13C24.625 12.6548 24.3452 12.375 24 12.375C23.6548 12.375 23.375 12.6548 23.375 13V26.2589L30.0581 32.9419C30.3021 33.186 30.6979 33.186 30.9419 32.9419C31.186 32.6979 31.186 32.3021 30.9419 32.0581L24.625 25.7411V13Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
