import React, { FC } from 'react';
import { useFormik } from 'formik';
import { Box, CloseButton, Flex, FormControl, SpacingStack, TextV2, useDisclosure, useToast } from '@withjoy/joykit';
import { WarningTriangle } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { CheckIcon } from '@assets/icons';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useHistory, useTranslation } from '@shared/core';
import { createContext } from '@shared/utils/createContext';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { DebouncedInputWebsiteUrl } from '@shared/components/DebouncedInputWebsiteUrl/DebouncedInputWebsiteUrl';
import { closeButtonOverrides, drawerOverrides } from '@shared/components/ResponsiveDialog/ResponsiveDialog.styles';
import { useUpdateWebsiteHandleMutation } from '@graphql/generated';
import { dialogOverrides, StyledActionButton } from './EditEventURLDialog.styles';
import { EditEventURLDialogProviderContext, EditEventURLDialogProviderProps, EventUrlFormFields } from './EditEventURlDialog.types';
import { EditEventURLTelemetryProvider, useEditEventURLTelemetry } from './EditEventURLDialog.telemetry';

const [Provider, useEditEventURLDialogContext] = createContext<EditEventURLDialogProviderContext>({ name: 'EditEventURLDialogProviderContext' });

const EditEventURLDialogProvider: FC<EditEventURLDialogProviderProps> = ({ pagePath, telemetryCategory, children }) => {
  const { isOpen: isEventUrlDialogOpen, onClose: closeEventUrlDialog, onOpen: openEventUrlDialog } = useDisclosure();

  const [updateWebsiteHandle, { loading: isUpdatingWebsiteHandle }] = useUpdateWebsiteHandleMutation();

  const { eventHandle, eventInfo } = useEventInfo();
  const history = useHistory();
  const { toast } = useToast();
  const telemetry = useEditEventURLTelemetry();

  const { t2 } = useTranslation('joykit');
  const trans = t2('editEventUrl');

  const formik = useFormik<EventUrlFormFields>({
    initialValues: {
      eventHandle: eventHandle || '',
      urlAvailable: true,
      customDomain: ''
    },
    onSubmit: () => {}
  });

  const isDisabled = !formik.values.urlAvailable || formik.values.eventHandle === eventHandle;

  const handleClose = useEventCallback(() => {
    formik.resetForm();
    closeEventUrlDialog();
  });

  const handleWebsiteHandleUpdate = useEventCallback(async () => {
    try {
      const newEventHandle = formik.values.eventHandle;
      if (eventInfo) {
        await updateWebsiteHandle({
          variables: {
            eventId: eventInfo?.eventId,
            website: newEventHandle
          }
        });
      }
      telemetry.eventURLChanged(eventInfo?.eventId || '', newEventHandle, eventHandle || '', newEventHandle, telemetryCategory);
      toast(trans.eventUrlUpdateSuccess, { icon: <CheckIcon /> });
      handleClose();
      const url = `/${newEventHandle}/edit${pagePath}${history.location.search}`;
      history.replace(url);
    } catch (error) {
      toast(trans.eventUrlUpdateError, { icon: <WarningTriangle /> });
    }
  });

  return (
    <Provider
      value={{
        openEditEventURLDialog: openEventUrlDialog,
        closeEditEventURLDialog: handleClose
      }}
    >
      {children}
      <ResponsiveDialog
        isOpen={isEventUrlDialogOpen}
        onClose={handleClose}
        hideDivider={true}
        removeBodyPadding
        hasCloseButton={false}
        drawerOverrides={{
          ...drawerOverrides,
          Content: {
            props: {
              ...drawerOverrides.Content?.props,
              boxShadow: '0px -7px 16px -8px rgba(0, 0, 0, 0.3), 0px -10px 27px -5px rgba(44, 41, 37, 0.25)'
            }
          },
          Body: { props: { ...drawerOverrides.Body?.props, paddingY: 8 } }
        }}
        dialogOverrides={dialogOverrides}
      >
        <SpacingStack spacing={7}>
          <Box>
            <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
              {trans.eventUrl}
            </TextV2>
            <CloseButton
              overrides={{
                Root: { props: { ...closeButtonOverrides.Root?.props, position: 'absolute', top: 5, right: 5 } },
                Icon: { props: { ...closeButtonOverrides.Icon?.props } }
              }}
              onClick={handleClose}
              disabled={isUpdatingWebsiteHandle}
              aria-label={'close dialog'}
            />
          </Box>

          <Box>
            <FormControl
              label={
                <TextV2 typographyVariant="label3" marginBottom={3}>
                  {trans.joyEventURL}
                </TextV2>
              }
            >
              <DebouncedInputWebsiteUrl
                fieldInputProps={formik.getFieldProps('eventHandle')}
                setValue={(val: string) => formik.setFieldValue('eventHandle', val, true)}
                value={(formik.values as EventUrlFormFields).eventHandle}
                eventId={eventInfo?.eventId}
                initialValue={eventHandle}
                setIsLoading={() => formik.setFieldValue('urlAvailable', false)}
                setIsValid={(valid: boolean) => formik.setFieldValue('urlAvailable', valid)}
              />
            </FormControl>
          </Box>

          <Flex
            minHeight={8}
            justifyContent="center"
            alignItems="flex-start"
            padding={5}
            gap={3}
            borderRadius={2}
            border="1px solid"
            borderColor="warning3"
            backgroundColor="warning1"
          >
            <WarningTriangle size={16} color="warning6" />
            <Flex flexDirection="column">
              <TextV2 typographyVariant="label3" color="mono14" minHeight={6}>
                {trans.pleaseNote}
              </TextV2>
              <TextV2 typographyVariant="label2" color="mono14">
                {trans.eventUrlNote}
              </TextV2>
            </Flex>
          </Flex>
        </SpacingStack>
        <Flex flexDirection="column" width="100%" gap={5} marginTop={pxToRem(56)}>
          <StyledActionButton intent="neutral" onClick={handleWebsiteHandleUpdate} loading={isUpdatingWebsiteHandle} disabled={isDisabled || isUpdatingWebsiteHandle}>
            {trans.save}
          </StyledActionButton>
          <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={handleClose} disabled={isUpdatingWebsiteHandle}>
            {trans.cancel}
          </StyledActionButton>
        </Flex>
      </ResponsiveDialog>
    </Provider>
  );
};

const EditEventURLWrapper: FC<EditEventURLDialogProviderProps> = props => {
  return (
    <EditEventURLTelemetryProvider>
      <EditEventURLDialogProvider {...props} />
    </EditEventURLTelemetryProvider>
  );
};

EditEventURLDialogProvider.displayName = 'EditEventURLDialogProvider';

export { EditEventURLWrapper as EditEventURLDialogProvider, useEditEventURLDialogContext };
