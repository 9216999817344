import React, { FC } from 'react';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { UserItem } from '../UserItem/UserItem';
import { useTranslation } from '@shared/core';
import { formatFullName } from '../../utils/format';
import { Invite, User } from '../../InviteAdminDialog';
import { AddCircle, WarningTriangle } from '@withjoy/joykit/icons';
import { useAuth } from '@shared/components/AuthProvider';

export interface InviteAdminViewProps {
  isLoading?: boolean;
  owner?: Maybe<User>;
  collaborators?: User[];
  invitations?: Invite[];
  onInviteCollaborator?: () => void;
  onResendInvitation?: (id: string) => void;
  onRemoveCollaborator?: (auth0Id: string, name: string, email: Maybe<string>) => void;
  onCancelInvitation?: (id: string, name: string, email?: string) => void;
  onTransferOwnership?: (auth0Id: string, name: string, email: Maybe<string>) => void;
  emailIsVerified?: boolean;
  emailVerificationUrl?: string;
  isDialogView: boolean;
}

export const InviteAdminView: FC<InviteAdminViewProps> = props => {
  const {
    isLoading,
    owner,
    collaborators = [],
    invitations = [],
    onInviteCollaborator,
    onResendInvitation,
    onRemoveCollaborator,
    onCancelInvitation,
    onTransferOwnership,
    emailIsVerified,
    emailVerificationUrl = '',
    isDialogView
  } = props;

  const { t2 } = useTranslation('joykit');
  const translations = t2('inviteAdmin');

  const handleResendInvitation = (id: string) => {
    if (onResendInvitation) {
      onResendInvitation(id);
    }
  };

  const handleCancelInvitation = (id: string, name: string) => {
    if (onCancelInvitation) {
      onCancelInvitation(id, name);
    }
  };

  const handleRevokeUser = (auth0Id: string, name: string, email: Maybe<string>) => {
    if (onRemoveCollaborator) {
      onRemoveCollaborator(auth0Id, name, email);
    }
  };

  const { currentUser } = useAuth();

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={9} width="100%">
      <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={6} width="100%">
        <TextV2 typographyVariant="hed3" color="mono14">
          {translations.owner}
        </TextV2>
        <UserItem
          firstName={owner?.firstName}
          lastName={owner?.lastName}
          email={owner?.email}
          isLoading={!owner}
          isCurrentUser={owner?.id === currentUser.profile?.id}
          showMenu={false}
        />
      </Flex>

      <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={6} width="100%">
        <TextV2 typographyVariant="hed3" color="mono14">
          {translations.collaborators}
        </TextV2>
        {isLoading && <UserItem isLoading={true} />}
        <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={5} width="100%">
          {collaborators.length > 0 && (
            <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={5} width="100%">
              {collaborators.map(({ id, email, firstName, lastName, auth0Id }) => (
                <UserItem
                  key={email}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  isCurrentUser={id === currentUser.profile?.id}
                  showMenu={id !== currentUser.profile?.id}
                  isDialogView={isDialogView}
                  onRevoke={() => handleRevokeUser(auth0Id, formatFullName(firstName, lastName), email)}
                  onTransfer={onTransferOwnership ? () => onTransferOwnership(auth0Id, formatFullName(firstName, lastName), email) : undefined}
                />
              ))}
            </Flex>
          )}
          {!emailIsVerified && !isLoading && (
            <Flex
              minHeight={9}
              justifyContent="center"
              alignItems="center"
              paddingX={5}
              gap={2}
              borderRadius={2}
              border="1px solid"
              borderColor="warning3"
              backgroundColor="warning1"
              width="100%"
            >
              <WarningTriangle size={16} color="warning6" />
              <TextV2 typographyVariant="label2" color="mono14">
                {translations.emailVerificationRequired}
              </TextV2>
              <ButtonV2
                as="a"
                width="fit-content"
                borderBottom="1px solid"
                borderColor="mono5"
                borderRadius={0}
                textDecoration="none"
                _hover={{
                  textDecoration: 'none',
                  borderColor: 'mono8'
                }}
                _active={{
                  borderColor: 'mono14'
                }}
                typographyVariant="label3"
                size="md"
                variant="link"
                shape="square"
                intent="neutral"
                href={emailVerificationUrl}
                target="_blank"
              >
                {translations.verifyEmail}
              </ButtonV2>
            </Flex>
          )}
        </Flex>
        {!isLoading && (
          <ButtonV2
            size="lg"
            variant="outline"
            intent="neutral"
            disabled={!emailIsVerified}
            shape="rounded"
            startIcon={<AddCircle size={24} />}
            onClick={onInviteCollaborator}
            typographyVariant="button2"
          >
            {translations.addCollaborator}
          </ButtonV2>
        )}
      </Flex>

      {invitations.length ? (
        <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={6} width="100%">
          <TextV2 typographyVariant="hed3" color="mono14">
            {translations.pendingInvites}
          </TextV2>

          <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" gap={5} width="100%">
            {invitations.map(({ email, name, id }) =>
              id && name ? (
                <UserItem
                  key={id}
                  firstName={name}
                  email={email}
                  onResend={() => handleResendInvitation(id)}
                  onRevoke={() => handleCancelInvitation(id, name)}
                  showMenu={true}
                  isDialogView={isDialogView}
                />
              ) : null
            )}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};

InviteAdminView.displayName = 'InviteAdminView';
