/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Star',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M10.478 2.67c.479-1.474 2.564-1.474 3.043 0l1.743 5.365a.4.4 0 0 0 .38.276h5.641c1.55 0 2.194 1.983.941 2.894l-4.564 3.316a.4.4 0 0 0-.145.447l1.743 5.365c.479 1.474-1.208 2.7-2.462 1.789l-4.564-3.316a.4.4 0 0 0-.47 0l-4.563 3.316c-1.254.911-2.941-.315-2.462-1.789l1.743-5.365a.4.4 0 0 0-.145-.447l-4.564-3.316c-1.254-.911-.609-2.894.94-2.894h5.641a.4.4 0 0 0 .38-.276l1.743-5.365zm1.902.371a.4.4 0 0 0-.761 0L9.876 8.406a1.6 1.6 0 0 1-1.522 1.106H2.714a.4.4 0 0 0-.235.724l4.564 3.316a1.6 1.6 0 0 1 .581 1.789L5.88 20.704a.4.4 0 0 0 .616.447l4.564-3.316a1.6 1.6 0 0 1 1.881 0l4.564 3.316a.4.4 0 0 0 .616-.447l-1.743-5.365a1.6 1.6 0 0 1 .581-1.789l4.564-3.316a.4.4 0 0 0-.235-.724h-5.641a1.6 1.6 0 0 1-1.522-1.106L12.38 3.041z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
