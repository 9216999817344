import { Box, DialogV2, DrawerV2, Flex, TextV2 } from '@withjoy/joykit';
import React, { useCallback } from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { ChecklistReminderButton } from '../ChecklistReminderButton';
import { Chat, Help, Tip } from '@withjoy/joykit/icons';
import { animationTransition } from '@shared/utils/animationTransition';
import { useTranslation } from '@shared/core';
import { ComponentWithAs } from '@shared/utils/forwardRef';
import globalWindow from '@shared/core/globals';
import { ChecklistItemWithDateProps } from '../ChecklistItem/ChecklistItem';
import { useDashboardChecklistTelemetry } from '../../DashboardChecklist.telemetry';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

export type ChecklistModalProps = Merge<
  Pick<ChecklistItemWithDateProps, 'id' | 'title' | 'action' | 'reminderAt' | 'onChangeReminderDate'>,
  { isOpen: boolean; onClose: () => void }
>;

const FooterButton: ComponentWithAs<
  'div',
  {
    icon: React.ReactNode;
  }
> = ({ children, icon, ...props }) => {
  return (
    <Flex columnGap={3} cursor={'pointer'} height={7} paddingX={4} alignItems={'center'} whiteSpace={'nowrap'} role="group" {...props}>
      {icon}
      <TextV2
        typographyVariant={'label1'}
        transition={animationTransition('color')}
        _groupHover={{
          color: 'linkHover'
        }}
      >
        {children}
      </TextV2>
    </Flex>
  );
};

const DialogInternal: React.FC<ChecklistModalProps> = ({ id, title, action, reminderAt, onChangeReminderDate, children }) => {
  const { t2 } = useTranslation('adminDashboardV2');
  const { helpCenter, support, items } = t2('checklist', 'groupModal');
  const telemetry = useDashboardChecklistTelemetry();

  const handleSupport = useCallback(() => {
    telemetry.supportLinkClicked('support');
    const Intercom = globalWindow.Intercom;
    if (Intercom) {
      Intercom('show');
    }
  }, [telemetry]);

  const handleHelpCenter = useCallback(() => {
    telemetry.supportLinkClicked('helpCenter');
  }, [telemetry]);

  const handleRemindMe = useCallback(
    (date: string) => {
      onChangeReminderDate(id, date, action, 'modal');
    },
    [action, id, onChangeReminderDate]
  );

  return (
    <DialogV2.Body>
      {/* Header */}
      <Flex position={'relative'} flexDirection={'column'} backgroundColor={'mono1'} rowGap={3} paddingX={6} paddingTop={6} paddingBottom={4} alignItems={'center'}>
        <DialogV2.CloseButton />
        <TextV2 typographyVariant={'hed3'}>{title}</TextV2>
        <TextV2 typographyVariant={'body1'} color={'mono10'} marginTop={-1} textAlign={'center'}>
          {items[action].subtitle}
        </TextV2>
        <ChecklistReminderButton reminderAt={reminderAt} onChangeReminderDate={handleRemindMe} />
      </Flex>
      <Flex backgroundColor={'mono2'} borderTop={'1px solid'} borderColor={'mono3'} color={'mono10'} height={9} alignItems={'center'} justifyContent={'center'} columnGap={2}>
        <Tip size={'sm'} />
        <TextV2 typographyVariant={'label1'}> {items[action].tip}</TextV2>
      </Flex>
      {/* Items */}
      <Box>{children}</Box>
      {/* Footer */}
      <Flex flexDirection={'column'} backgroundColor={'mono1'} rowGap={3} padding={6} alignItems={'center'} borderTop={'1px solid'} borderColor={'mono3'}>
        <TextV2 typographyVariant={'hed1'}>Need more help?</TextV2>
        <Flex columnGap={3} color={'mono10'}>
          <FooterButton icon={<Help size="sm" />} as="a" href="https://help.withjoy.com/" target="_blank" onClick={handleHelpCenter}>
            {helpCenter}
          </FooterButton>
          <FooterButton icon={<Chat size="sm" />} onClick={handleSupport}>
            {support}
          </FooterButton>
        </Flex>
      </Flex>
    </DialogV2.Body>
  );
};

export const ChecklistItemModal: React.FC<ChecklistModalProps> = props => {
  const { isOpen, onClose } = props;
  const isMobile = useIsMobileScreen();

  if (isMobile) {
    return (
      <DrawerV2
        isOpen={isOpen}
        onClose={onClose}
        overrides={{
          Content: {
            props: {
              borderTopRightRadius: pxToRem(32),
              borderTopLeftRadius: pxToRem(32),
              maxHeight: '80vh'
            }
          },
          Body: {
            props: {
              padding: '0!important'
            }
          }
        }}
        anchor="bottom"
        size={'auto'}
      >
        <DialogInternal {...props} />
      </DrawerV2>
    );
  }

  return (
    <DialogV2
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      overrides={{
        Content: { props: { width: [null, pxToRem(480)], height: [null, 'auto'], maxHeight: [null, '80vh'], overflow: 'hidden' } },
        Body: { props: { paddingX: 0, paddingTop: '0!important', paddingBottom: '0!important', overflowY: 'auto' } }
      }}
    >
      <DialogInternal {...props} />
    </DialogV2>
  );
};
