// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@withjoy/joykit';
import { useSpring, animated, useChain, ReactSpringHook } from 'react-spring';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import globalWindow from '@shared/core/globals';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

const Container = styled(animated.div)<{ background: string; height: string }>`
  position: absolute;
  width: 100%;
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 1;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
  background: ${props => props.background};
  &.hidden {
    display: none;
  }

  @media screen and (min-width: 769px) {
    transform: translate3d(0, 0, 0);
  }
`;

const hasOpacitySpringCrossedThreshold = (opacity: number) => (typeof opacity === 'number' ? opacity <= 0.18 : true);

type SketchedJoyLogoProps = Readonly<{
  containerBgColor?: string;
  containerHeight?: string;
  logoFill?: string;
  logoFillFrom?: string;
  shouldAnimate: boolean;
  onAnimateStop?: () => void;
}>;

export const SketchedJoyLogo = React.memo<SketchedJoyLogoProps>(props => {
  const { containerHeight = '100%', containerBgColor = '#ffffff', logoFill = '#322E53', logoFillFrom = '#ffffff', shouldAnimate, onAnimateStop } = props;
  const [{ animateSvg, animateContainer, isHidden }, setState] = useState({ animateSvg: false, animateContainer: false, isHidden: false });

  const containerRef = useRef<HTMLDivElement>(null);
  const springRef = useRef<ReactSpringHook>(null);
  const fillRef = useRef<ReactSpringHook>(null);

  const animateStepRef = useRef<{ hasFinishedAnimating: boolean }>(() => ({ hasFinishedAnimating: !shouldAnimate }));

  const timeoutRefs = useRef<{ startTimeoutId: number | undefined; restTimeoutId: number | undefined }>({ startTimeoutId: undefined, restTimeoutId: undefined });

  const springProps = useSpring<{ first: number; third: number }>({
    from: { first: 98, third: 246 },
    to: animateSvg ? { first: 0, third: 0 } : { first: 98, third: 246 },
    ref: springRef,
    onRest: () => {
      if (isHidden) {
        return;
      }
      if (shouldAnimate) {
        // IFrame has not been initialized, continue animation sequence
        setState({ animateSvg: !animateSvg, animateContainer: false, isHidden: false });
      } else {
        // Path tracing will take longer than fill
        if (animateSvg) {
          // Logo is still rendered in the DOM, initiate hide sequence
          setState({ animateSvg: false, animateContainer: false, isHidden: false });
        } else if (!animateSvg && !animateContainer) {
          // 1. Logo is no longer visible
          // 2. Container is still opaque
          setState({ animateSvg: false, animateContainer: true, isHidden: false });
        }
      }
    }
  });

  const handleOnAnimateStop = useEventCallback(() => {
    if (!animateStepRef.current.hasFinishedAnimating) {
      animateStepRef.current.hasFinishedAnimating = true;
      onAnimateStop?.();
    }
  });

  const fillProps = useSpring({
    from: { color: logoFillFrom },
    to: { color: animateSvg ? logoFill : logoFillFrom },
    ref: fillRef
  });

  const containerOpacityProps = useSpring({
    pointerEvents: isHidden || animateContainer ? 'none' : 'all',
    opacity: isHidden || animateContainer ? 0 : 1,
    config: {
      delay: 500,
      duration: 500,
      easing: cubicBezierEasingFn
    },
    onFrame: item => {
      if (hasOpacitySpringCrossedThreshold(item?.opacity)) {
        // Container is about to become very translucent - reveal the iframe slightly before
        handleOnAnimateStop();
        containerRef.current?.classList.toggle('hidden', true);
      }
    },
    onRest: () => {
      if (!isHidden && !shouldAnimate && animateContainer) {
        timeoutRefs.current.restTimeoutId = globalWindow.setTimeout(() => {
          setState({ animateSvg: false, animateContainer: false, isHidden: true });
        }, 1500);
      }
    }
  });

  useEffect(() => {
    // animateSvg defaults to false
    // animateContainer defaults to false
    timeoutRefs.current.startTimeoutId = globalWindow.setTimeout(() => {
      if (shouldAnimate) {
        animateStepRef.current.hasFinishedAnimating = false;
        containerRef.current?.classList.toggle('hidden', false);
      }
      setState({ animateSvg: shouldAnimate, animateContainer: !shouldAnimate, isHidden: !shouldAnimate });
    }, 1000);
  }, [shouldAnimate]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const { startTimeoutId, restTimeoutId } = timeoutRefs.current;
      globalWindow.clearTimeout(startTimeoutId);
      globalWindow.clearTimeout(restTimeoutId);
    };
  }, []);

  // https://spectrum.chat/react-spring/general/unable-to-get-usechain-to-work-with-two-springs~90f925a7-8233-4b27-afb0-ca07dea2a235
  useChain(animateSvg ? [{ current: springRef.current }, { current: fillRef.current }] : [{ current: fillRef.current }, { current: springRef.current }], [0, 0.2]);

  return (
    <Container ref={containerRef} background={containerBgColor} height={containerHeight} style={containerOpacityProps}>
      <animated.svg width={81} height={50} viewBox="0 0 81 50" fill={fillProps.color}>
        <animated.g clipPath="url(#A)">
          <animated.path
            fillRule="evenodd"
            d="M10.091 38.312c.046-.088.123-.156.216-.191 2.591-1.231 4.797-4.974 7.082-14.628l.997-4.358 2.637-11.462c2.098-9.269 9.308-7.91 11.574-7.073.062.023.117.061.161.11s.075.109.09.173.015.131-.001.195-.048.123-.093.172c-.758.857-1.655 2.994-2.876 8.087l-3.871 15.977c-2.492 10.353-10.392 13.209-15.504 13.584-.098.014-.198-.01-.281-.065s-.141-.139-.166-.236-.012-.198.034-.286zm67.168-27.565c3.142 1.005 3.103 4.354 2.64 6.511-.813 3.385-2.114 6.633-3.861 9.643-.047.084-.122.148-.212.182s-.189.033-.28 0-.166-.098-.213-.181-.063-.182-.044-.276a15.01 15.01 0 0 0-1.97-10.077c-2.502-4.098 1.704-6.521 3.94-5.802zM55.737 13.79c2.384-2.344 10.412-6.826 13.318 5.231 1.151 4.538 1.092 9.299-.169 13.808s-3.682 8.609-7.021 11.891c-.065.058-.147.094-.233.102s-.174-.01-.249-.054-.135-.11-.17-.189-.045-.168-.027-.253c2.325-8.737.877-29.649-5.201-29.846-.076-.007-.149-.036-.21-.083s-.107-.111-.133-.183-.031-.151-.013-.226.055-.143.11-.197zm1.852 33.56c.093-.02.19-.006.274.039s.149.118.184.206.038.186.009.276-.09.167-.171.217a9.79 9.79 0 0 1-6.255 1.901c-5.91-.266-6.156-4.925-4.423-7.112.933-1.084 2.184-1.846 3.576-2.177.066-.007.133.002.194.026s.116.064.159.114.073.111.087.176.012.132-.007.196c-.778 2.591 1.221 7.053 6.373 6.137zM33.298 15.445c3.242-2.797 7.371-4.354 11.653-4.393 6.688 0 11.486 4.275 11.486 10.835a17.73 17.73 0 0 1-6.028 12.717c-3.159 2.78-7.219 4.32-11.426 4.334-6.915 0-11.663-4.334-11.663-10.953a17.89 17.89 0 0 1 5.979-12.539zm6.954 20.942c3.645-1.921 6.107-11.259 6.511-12.805.522-1.793 2.088-12.579-3.132-10.038-3.511 1.751-5.904 10.541-6.462 12.589v.001l-.059.215c-.66 2.601-2.128 12.756 3.142 10.037zm-38.84-.857c.048.066.115.117.192.144s.161.031.24.009.15-.066.203-.129.086-.14.094-.221c.099-1.675 1.901-3.694 4.984-3.467 2.177.167 3.29-3.29 1.487-5.112-2.226-2.256-5.536-1.369-7.201.985-2.305 3.26-1.409 6.038 0 7.791z"
            stroke={logoFill}
            strokeWidth=".6"
            strokeDasharray={98}
            strokeDashoffset={springProps.first}
          />
        </animated.g>
        <defs>
          <clipPath id="A">
            <animated.path
              fill={fillProps.color}
              transform="translate(.004)"
              d="M0 0h80.122v50H0z"
              stroke={logoFill}
              strokeWidth=".6"
              strokeDasharray={246}
              strokeDashoffset={springProps.third}
            />
          </clipPath>
        </defs>
      </animated.svg>
    </Container>
  );
});
