import { useFeatureValue } from '@shared/core/featureFlags/featureFlags.provider';
import { verifyDatePayload } from './DashboardContentV3.utils';
import { useGetEventCreationDataQuery } from '@graphql/generated';
import { useEventTypeConfig } from '@shared/utils/eventType';

export const useIsDashboardContentV3Enabled = ({ eventHandle }: { eventHandle: string }) => {
  const { config } = useEventTypeConfig();
  const { loading: loadingData, data } = useGetEventCreationDataQuery({
    variables: {
      eventHandle
    },
    batchMode: 'fast'
  });

  const adminDashboardV3StartDatePayload = useFeatureValue('adminDashboardV3StartDate').payload;
  const isPayloadValid = verifyDatePayload(adminDashboardV3StartDatePayload, new Date(data?.eventByName?.firebaseCreateAt?.timestamp));

  const { loading: loadingFeatureFlag, value: adminDashboardV3Value } = useFeatureValue('adminDashboardV3', { skip: !config?.adminDashboardV3 || !isPayloadValid });

  return {
    loading: loadingData || loadingFeatureFlag,
    isEnabled: adminDashboardV3Value === 'treatment',
    dataV3: data
  };
};
