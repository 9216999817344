import React, { useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { Flex, TextV2, useDisclosure } from '@withjoy/joykit';
import { SkeletonGroup, SkeletonThumbnail, Skeleton } from '@shared/components/Skeleton';
import { Wrapper, InformationWrapper, Name, Email, Content } from './UserItem.styles';
import { formatFullName } from '../../utils/format';
import { LetterAvatar } from '../LetterAvatar';
import { ResponsiveMenu } from '@shared/components/ResponsiveMenu';
import { Send, Trash } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';

interface UserItemProps {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  email?: Maybe<string>;
  onTransfer?: () => void;
  onRevoke?: () => void;
  onResend?: () => void;
  isLoading?: boolean;
  isCurrentUser?: boolean;
  showMenu?: boolean;
  isDialogView?: boolean;
}

export const UserItem: React.FC<UserItemProps> = props => {
  const { firstName, lastName, email, onTransfer, onRevoke, onResend, isLoading, isCurrentUser = false, showMenu = true, isDialogView = false } = props;

  const { t2 } = useTranslation('joykit');
  const translations = t2('inviteAdmin');
  const fullname = formatFullName(firstName, lastName);

  const { isOpen: isDotsOpen, onClose: onCloseDots, onToggle: onToggleDots } = useDisclosure();

  const actionItems = useMemo(() => {
    const actions = [];
    if (onTransfer) {
      actions.push({ title: translations.transferOwnership, onClick: onTransfer, icon: Send });
    }
    if (onResend) {
      actions.push({
        title: translations.resendInvite,
        onClick: () => {
          onResend();
          onCloseDots();
        },
        icon: Send
      });
    }
    if (onRevoke) {
      actions.push({ title: translations.revokeInvite, onClick: onRevoke, icon: Trash });
    }
    return actions;
  }, [onCloseDots, onResend, onRevoke, onTransfer, translations.resendInvite, translations.revokeInvite, translations.transferOwnership]);

  return (
    <Wrapper gap={4} backgroundColor="white" border="1px solid" borderColor="mono3">
      <Flex alignSelf="flex-start">
        <SkeletonGroup as="span" isReady={!isLoading} placeholder={<SkeletonThumbnail skeletonSize={9} variant="circle" />}>
          <LetterAvatar name={fullname || email} />
        </SkeletonGroup>
      </Flex>
      <Content gap={1}>
        {isLoading ? (
          <Skeleton isReady={!isLoading} height={8} width={5} flex={1} />
        ) : (
          <>
            <InformationWrapper gap={pxToRem(2)}>
              {fullname ? (
                <Name typographyVariant="button2" color="mono14">
                  {fullname}
                </Name>
              ) : null}
              <Email typographyVariant="body1" color="mono12">
                {email}
              </Email>
            </InformationWrapper>
          </>
        )}
      </Content>
      {isCurrentUser && (
        <Flex height={7} paddingY={3} paddingX={4} justifyContent="center" alignItems="center" gap={1} borderRadius={2} backgroundColor="mono2">
          <TextV2 typographyVariant="label2" color="mono14">
            {translations.you}
          </TextV2>
        </Flex>
      )}
      {showMenu && !isLoading && (
        <ResponsiveMenu
          isOpen={isDotsOpen}
          onToggle={onToggleDots}
          actions={actionItems}
          lastOptionNeutralStyle={false}
          usePortal={isDialogView}
          horizontalIcon={true}
          iconSize={24}
        />
      )}
    </Wrapper>
  );
};
