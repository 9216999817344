import { Flex, styled, TextV2, ButtonV2 } from '@withjoy/joykit';
import { linkSharedStates } from '@shared/utils/style/buttons';

export const Wrapper = styled(Flex)`
  padding: 1rem;
  border-radius: 0.5rem;
  align-items: center;
  width: 100%;
`;

export const Content = styled(Flex)`
  flex: 1;
  flex-wrap: wrap;
  overflow: hidden;
`;

export const InformationWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  min-width: 170px;
`;

export const Name = styled(TextV2)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Email = styled(TextV2)`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsWrapper = styled(Flex)`
  padding-right: 0.25rem;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledActionButton = styled(ButtonV2)`
  ${linkSharedStates};
  height: 1.1875rem;
  &:hover {
    text-decoration: none;
  }
`;
