import { isAfter, isDate } from 'date-fns';

interface AdminDashboardV3Payload {
  startDate: string;
}

export const verifyDatePayload = (payload: unknown, eventCreationDate?: Date): payload is AdminDashboardV3Payload => {
  if (!eventCreationDate) {
    return false;
  }
  if (payload && typeof payload === 'object' && 'startDate' in payload && typeof payload.startDate === 'string') {
    const startDate = new Date(payload.startDate);
    return isDate(startDate) && isAfter(eventCreationDate, startDate);
  }
  return false;
};
