import React, { useCallback, useMemo, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import { GetDashboardChecklistDataQuery, useGetDashboardChecklistDataQuery } from '@graphql/generated';
import { useDashboardChecklistTelemetry } from './DashboardChecklist.telemetry';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useIsDashboardContentV3Enabled } from '@apps/admin/routes/Dashboard/components/DashboardContentV3';

type DashboardChecklistContext = {
  toggleBottomSheet?: () => void;
  isChecklistExpanded: boolean;
  toggleChecklist: () => void;
  checklistDataLoading: boolean;
  checklistData?: GetDashboardChecklistDataQuery;
  checklistItemsCompleted: number;
  checklistItemsTotal: number;
  adminDashboardV3Enabled: boolean;
};

type DashboardChecklistContextProps = {
  eventHandle: string;
  eventId: string;
};

const [Provider, useDashboardChecklistContext] = createContext<DashboardChecklistContext>({ name: 'DashboardChecklist' });

const DashboardChecklistProvider: React.FC<DashboardChecklistContextProps> = ({ eventHandle, eventId, children }) => {
  const { isEnabled: adminDashboardV3Enabled } = useIsDashboardContentV3Enabled({ eventHandle });
  const telemetry = useDashboardChecklistTelemetry();
  const isMobile = useIsMobileScreen();

  const [isChecklistExpanded, setIsChecklistExpanded] = useState(false);

  const toggleChecklist = useCallback(() => {
    setIsChecklistExpanded(prev => !prev);
  }, []);

  const { loading: checklistDataLoading, data: checklistData } = useGetDashboardChecklistDataQuery({
    batchMode: 'fast',
    variables: {
      eventHandle
    },
    skip: !eventHandle,
    onCompleted: () => {
      telemetry.checklistDataLoaded();

      if (isMobile || adminDashboardV3Enabled) return;
      toggleChecklist();
    }
  });

  const [checklistItemsCompleted, checklistItemsTotal] = useMemo(() => {
    if (checklistDataLoading) return [0, 0];

    const items = checklistData?.eventByName?.checklist?.items ?? [];
    const totalItems = items.length;
    const completedItems = items.filter(item => item.completedAt !== null).length;

    return [completedItems, totalItems];
  }, [checklistData, checklistDataLoading]);

  const ctx = useMemo<DashboardChecklistContext>(
    () => ({
      isChecklistExpanded,
      toggleChecklist,
      checklistDataLoading,
      checklistData,
      checklistItemsCompleted,
      checklistItemsTotal,
      adminDashboardV3Enabled
    }),
    [isChecklistExpanded, toggleChecklist, checklistDataLoading, checklistData, checklistItemsCompleted, checklistItemsTotal, adminDashboardV3Enabled]
  );

  return <Provider value={ctx}>{children}</Provider>;
};

export { DashboardChecklistProvider, useDashboardChecklistContext };
