import { ProgressBar } from '@shared/components/ProgressBar';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import React, { useMemo } from 'react';
import { pxToRem } from '@withjoy/joykit/theme';
import { Close } from '@withjoy/joykit/icons';
import { animationTransition } from '@shared/utils/animationTransition';
import { useTranslation } from '@shared/core';
import { SkeletonThumbnail } from '@shared/components/Skeleton';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useDashboardChecklistContext } from '../../DashboardChecklist.provider';

export const ChecklistProgressBar: React.FC<{
  isExpanded: boolean;
  onToggle: () => void;
  progressPercentage: number;
  completedItems: number;
  daysUntilEvent: number | null;
  showSkeleton: boolean;
}> = ({ isExpanded, onToggle, progressPercentage, completedItems, daysUntilEvent, showSkeleton }) => {
  const isMobile = useIsMobileScreen();
  const { t } = useTranslation('adminDashboardV2');
  const { adminDashboardV3Enabled } = useDashboardChecklistContext();
  const { titleExpanded, titleCollapsedUntil, titleCollapseTomorrow, titleCollapsedToday, titleCollapsedPast } = t('checklist', 'progressBar');

  const dateToTitle = useMemo(() => {
    if (daysUntilEvent === null) return titleExpanded();
    if (daysUntilEvent === 0) return titleCollapsedToday();
    if (daysUntilEvent === 1) return titleCollapseTomorrow();
    if (daysUntilEvent < 0) return titleCollapsedPast();
    return titleCollapsedUntil({ count: daysUntilEvent });
  }, [daysUntilEvent, titleCollapseTomorrow, titleCollapsedPast, titleCollapsedToday, titleCollapsedUntil, titleExpanded]);

  return (
    <Box
      paddingX={7}
      paddingTop={adminDashboardV3Enabled ? 7 : [4, 7]}
      paddingBottom={adminDashboardV3Enabled ? 0 : [isExpanded ? 0 : 7, 0]}
      {...(adminDashboardV3Enabled && {
        onClick: onToggle,
        cursor: 'pointer'
      })}
    >
      {(!isMobile || adminDashboardV3Enabled) && (
        <Box
          position={'absolute'}
          top={3}
          right={3}
          transform={isExpanded ? 'rotate(0deg)' : 'rotate(45deg)'}
          transition={animationTransition('transform')}
          transformOrigin={'center'}
          cursor={'pointer'}
          {...(!adminDashboardV3Enabled && {
            onClick: onToggle
          })}
        >
          <Close size={'sm'} />
        </Box>
      )}
      {showSkeleton ? (
        <Box paddingBottom={adminDashboardV3Enabled ? 7 : 0}>
          <SkeletonThumbnail height={pxToRem(24)} width={pxToRem(200)} />
          <SkeletonThumbnail height={5} marginTop={pxToRem(10)} width={'100%'} />
        </Box>
      ) : (
        <>
          <Box height={pxToRem(34)} position={'relative'} overflow={'hidden'}>
            {adminDashboardV3Enabled ? (
              <TextV2 typographyVariant={'hed3'}>{titleExpanded()}</TextV2>
            ) : (
              <Box position={'absolute'} top={isExpanded ? 0 : pxToRem(-28)} transition={animationTransition('top')} width={'100%'}>
                <TextV2 opacity={isExpanded ? 1 : 0} transition={animationTransition('opacity')} typographyVariant={'hed3'}>
                  {titleExpanded()}
                </TextV2>
                <TextV2 opacity={isExpanded ? 0 : 1} transition={animationTransition('opacity')} typographyVariant={'hed3'} textAlign={'center'}>
                  {dateToTitle}
                </TextV2>
              </Box>
            )}
          </Box>
          <ProgressBar height={5} progressPercentage={progressPercentage} backgroundColor={'mono3'} fillColor={'linkHover'} />
          {adminDashboardV3Enabled && <ChecklistProgressBarHidableContent completedItems={completedItems} daysUntilEvent={daysUntilEvent} />}
        </>
      )}
    </Box>
  );
};

export const ChecklistProgressBarHidableContent: React.FC<{
  completedItems: number;
  daysUntilEvent: number | null;
}> = ({ completedItems, daysUntilEvent }) => {
  const { adminDashboardV3Enabled } = useDashboardChecklistContext();
  const { t } = useTranslation('adminDashboardV2');
  const { counter0, counter1, counter2, counter3, counter4, counter5, counter6, countdown, countdownToday, countdownCongrats } = t('checklist', 'progressBar');

  const completedItemsText = useMemo(() => {
    if (completedItems === 1) return counter1();
    if (completedItems === 2) return counter2();
    if (completedItems === 3) return counter3();
    if (completedItems === 4) return counter4();
    if (completedItems === 5) return counter5();
    if (completedItems === 6) return counter6();
    return counter0();
  }, [completedItems, counter0, counter1, counter2, counter3, counter4, counter5, counter6]);

  const countdownText = useMemo(() => {
    if (daysUntilEvent === null) return '';
    if (daysUntilEvent === 0) return countdownToday();
    if (daysUntilEvent < 0) return countdownCongrats();
    return countdown({ count: daysUntilEvent });
  }, [countdown, countdownCongrats, countdownToday, daysUntilEvent]);

  return (
    <Flex justifyContent={'space-between'} paddingX={adminDashboardV3Enabled ? 0 : 7} paddingTop={pxToRem(10)} paddingBottom={7}>
      <TextV2 typographyVariant={'label1'} color={'mono12'}>
        {completedItemsText}
      </TextV2>
      <TextV2 typographyVariant={'label1'} fontWeight={'medium'}>
        {countdownText}
      </TextV2>
    </Flex>
  );
};
