/*
  ResponsiveDialog
    Description:
    This component is a wrapper around the JoyKit Dialog and Drawer components.
    It will render a Drawer on mobile and a Dialog on tablet and desktop to be device responsive.
    The idea is to focus on the content of the dialog/drawer and not the implementation details of the component itself.

    Caveats:
    Behind scenes the Drawer and Dialog components share the same children component, so if you see a Dialog.Body and a Drawer.Body
    they are the same component at the moment. This is because the Drawer and Dialog components are wrappers around a Modal component.

    References:
    This component was inspired by the DashboardDialog created by @fabian-with-joy.
*/
import React from 'react';
import { DialogV2, DrawerV2, DrawerV2Props, DialogV2Props, CloseButton, Divider, CloseButtonProps } from '@withjoy/joykit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

import { HeaderWrapper, closeButtonOverrides as defaultCloseButtonOverrides, DialogTitle, drawerOverrides as defaultDrawerOverrides, BodyWrapper } from './ResponsiveDialog.styles';
import { useTranslation } from '@shared/core';

interface ResponsiveDialogProps {
  title?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  drawerOverrides?: DrawerV2Props['overrides'];
  dialogOverrides?: DialogV2Props['overrides'];
  closeButtonOverrides?: CloseButtonProps['overrides'];
  hasCloseButton?: boolean;
  hideDivider?: boolean;
  hideFooterDivider?: boolean;
  renderTitle?: () => React.ReactNode;
  renderFooter?: (onClose: () => void) => React.ReactNode;
  stickyBehavior?: DialogV2Props['stickyBehavior'];
  scrollBehavior?: DialogV2Props['scrollBehavior'];
  disableAutoFocus?: DialogV2Props['disableAutoFocus'];
  disableCloseOnEscapeKeyClick?: DialogV2Props['disableCloseOnEscapeKeyClick'];
  disableCloseOnOutsideClick?: DialogV2Props['disableCloseOnOutsideClick'];
  removeBodyPadding?: boolean;
  dialogSize?: DialogV2Props['size'];
  setMobileBreakpoints?: Partial<Record<string, boolean>>;
  disableFocusLock?: DialogV2Props['disableFocusLock'];
}

export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  title,
  children,
  isOpen,
  onClose,
  dialogSize,
  drawerOverrides = defaultDrawerOverrides,
  dialogOverrides,
  closeButtonOverrides = defaultCloseButtonOverrides,
  hasCloseButton = true,
  hideDivider = false,
  hideFooterDivider = false,
  renderTitle,
  renderFooter,
  stickyBehavior,
  scrollBehavior,
  disableAutoFocus,
  disableCloseOnEscapeKeyClick = false,
  disableCloseOnOutsideClick = false,
  removeBodyPadding = false,
  setMobileBreakpoints = { xxs: true, sm: false },
  disableFocusLock = false
}) => {
  const [isMobile] = useResponsive({ values: setMobileBreakpoints });

  const { t2 } = useTranslation('sharedComponents');
  const translations = t2('dialog');

  const content = (
    <>
      {title || hasCloseButton ? (
        <>
          <DialogV2.Header>
            <HeaderWrapper>
              <DialogTitle as="h3">{title}</DialogTitle>
              {hasCloseButton && <CloseButton overrides={closeButtonOverrides} onClick={onClose} aria-label={translations.closeDialog} />}
            </HeaderWrapper>
            {renderTitle && renderTitle()}
          </DialogV2.Header>
          {!hideDivider && <Divider />}
        </>
      ) : null}
      <DialogV2.Body>
        <BodyWrapper removeBodyPadding={removeBodyPadding}>{children}</BodyWrapper>
      </DialogV2.Body>
      {renderFooter && (
        <>
          {!hideFooterDivider && <Divider />}
          <DialogV2.Footer>{renderFooter(onClose)}</DialogV2.Footer>
        </>
      )}
    </>
  );

  return isMobile ? (
    <DrawerV2
      isOpen={isOpen}
      onClose={onClose}
      anchor="bottom"
      enableReturnFocusOnClose
      useBackdrop
      overrides={drawerOverrides}
      stickyBehavior={stickyBehavior}
      disableAutoFocus={disableAutoFocus}
      disableCloseOnEscapeKeyClick={disableCloseOnEscapeKeyClick}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      disableFocusLock={disableFocusLock}
    >
      {content}
    </DrawerV2>
  ) : (
    <DialogV2
      disableCloseOnEscapeKeyClick={disableCloseOnEscapeKeyClick}
      disableCloseOnOutsideClick={disableCloseOnOutsideClick}
      isOpen={isOpen}
      onClose={onClose}
      overrides={dialogOverrides}
      stickyBehavior={stickyBehavior}
      scrollBehavior={scrollBehavior}
      disableAutoFocus={disableAutoFocus}
      size={dialogSize}
      disableFocusLock={disableFocusLock}
    >
      {content}
    </DialogV2>
  );
};
