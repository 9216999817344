/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Lightning Bolt',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.39498 3.41867L4.72168 8.89938H7.43721C7.92952 8.89938 8.20831 9.35602 8.15245 9.75025C8.01712 10.7056 7.80147 11.6567 7.62651 12.6057L11.2864 7.0395H8.56371C8.05794 7.0395 7.77267 6.55734 7.85401 6.15643C7.94985 5.68404 8.1734 4.54083 8.39498 3.41867ZM4.33581 9.47478L4.34158 9.46641C4.3397 9.46921 4.33778 9.472 4.33581 9.47478ZM9.83409 1.82749C9.92712 1.44145 9.69899 1.12137 9.42303 0.998621C9.13484 0.870434 8.74732 0.928996 8.52156 1.25416L3.43294 8.84646C3.10044 9.3278 3.44029 9.99938 4.04064 9.99938H6.98847L6.2136 14.2025C6.15213 14.5396 6.33831 14.8768 6.65454 15.0081C6.91624 15.1167 7.31084 15.0861 7.53755 14.7432L12.5814 7.07183C12.9032 6.55268 12.5101 5.9395 11.9603 5.9395H9.01919C9.2219 4.91793 9.60169 2.97034 9.83409 1.82749Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2192 3.24507L6.11579 13.7365H11.0842C11.6701 13.7365 12.0276 14.282 11.9561 14.7821C11.6681 16.7942 11.2104 18.7889 10.8383 20.7867L17.8927 10.1663H12.9183C12.3185 10.1663 11.9488 9.59192 12.0526 9.08524C12.2509 8.11752 12.7845 5.40687 13.2192 3.24507ZM5.79211 14.2143L5.79727 14.2068C5.79559 14.2093 5.79387 14.2118 5.79211 14.2143ZM14.6979 1.99143C14.8157 1.51606 14.5333 1.12654 14.1952 0.977664C13.8449 0.823445 13.3735 0.891205 13.094 1.28973L4.81069 13.5237C4.39256 14.1205 4.82673 14.9365 5.55429 14.9365H10.7072L9.3824 22.0501C9.29408 22.5349 9.59912 22.8975 9.92524 23.0315C10.2467 23.1636 10.7236 23.1259 10.9996 22.7127L19.2068 10.3562C19.6121 9.70898 19.1114 8.96625 18.4482 8.96625H13.3013C13.606 7.45588 14.291 3.97201 14.6979 1.99143Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.0191 3.38903L7.77774 18.5152H14.7793C15.4301 18.5152 15.8456 19.1365 15.7396 19.7057L14.0751 28.6433L24.2301 13.3548H17.2247C16.6147 13.3548 16.1447 12.7472 16.2664 12.1536C16.5621 10.7106 17.4157 6.36668 18.0191 3.38903ZM19.4025 2.61055C19.5346 2.08405 19.2242 1.65262 18.8466 1.48636C18.457 1.31481 17.9346 1.3895 17.6227 1.83425L6.5792 18.145C6.11448 18.8064 6.59501 19.7152 7.406 19.7152H14.5172L12.7073 29.4334C12.6227 29.8931 12.8794 30.3465 13.31 30.5235C13.6708 30.6717 14.1964 30.6271 14.5001 30.1723L25.4399 13.7019C25.8915 12.9807 25.3352 12.1548 24.5979 12.1548H17.4908C17.8676 10.2977 18.8368 5.36356 19.4025 2.61055Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.7518 2.93082L10.6308 28.2179L10.6292 28.2204L10.632 28.2204H22.1015C22.884 28.2204 23.3999 28.9604 23.2966 29.6818C23.2954 29.6904 23.2939 29.6991 23.2923 29.7076L20.4261 45.0976C25.5061 37.4496 37.3733 19.5793 37.3733 19.5793L37.3708 19.5765L37.3741 19.5765M37.3708 19.5765H25.9055C25.1231 19.5765 24.6072 18.8365 24.7105 18.1151C24.7122 18.1028 24.7144 18.0904 24.7169 18.0782C25.2308 15.5708 26.8252 7.44422 27.7518 2.93082M28.9926 3.1026C29.1594 2.44926 28.7787 1.91391 28.3043 1.70504C27.8175 1.49071 27.1686 1.58276 26.7771 2.14106L9.59962 27.5114C9.01997 28.3336 9.61578 29.4704 10.632 29.4704H22.0577C22.0577 29.4704 19.8818 41.1931 19.1817 44.9524L19.1812 44.9549C19.0605 45.6174 19.4715 46.1168 19.9332 46.3065C20.3899 46.4942 21.0374 46.4334 21.4109 45.8742L21.4118 45.8729L38.4144 20.2752L38.4234 20.2611C38.9891 19.3579 38.2953 18.3265 37.375 18.3265H25.9494C25.9494 18.3265 28.2345 6.79209 28.9926 3.1026Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
