import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { Stat, StatCard } from './DashboardStats';

export const telemetryObject = createTelemetryObject({
  actions: {
    // Stats
    statsDataLoaded: () => ({
      action: 'StatsDataLoaded',
      category: 'DashboardStats'
    }),
    statItemClicked: (item: Stat['id'], card: StatCard['id']) => ({
      action: 'StatItemClicked',
      category: 'DashboardStats',
      extraInfo: {
        item,
        card
      }
    })
  }
});

export const { useTelemetry: useDashboardStatsTelemetry } = createTelemetry(telemetryObject);
