import { useFullPageLoader } from '@shared/components/JoyLogoLoader/FullPageLoaderProvider';
import React from 'react';
import { Suspense } from '../Suspense';

type Props = Readonly<{
  loaderKey: string;
}>;

const InternalFallback: React.FC<{ loaderKey: string }> = ({ loaderKey }) => {
  useFullPageLoader({ key: loaderKey, initialIsLoading: false });
  return <></>;
};

export const SuspenseWithPageLoader: React.FC<Props> = ({ children, loaderKey }) => {
  return <Suspense fallback={<InternalFallback loaderKey={loaderKey} />}>{children}</Suspense>;
};
