import { buttonSharedStates } from '@shared/utils/style/buttons';
import { ButtonV2, DialogV2Props, styled } from '@withjoy/joykit';

export const StyledActionButton = styled(ButtonV2)`
  ${buttonSharedStates};
`;

export const dialogOverrides: NonNullable<DialogV2Props['overrides']> = {
  Content: { props: { width: ['100%', '480px'] } },
  Body: { props: { paddingX: 7, paddingY: 8 } }
};
