import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from '@react-router';
import App from './App';
import { routes } from './router';
import { ErrorBoundary } from './ErrorBoundary';
import '@shared/core/i18n/i18n';
import { FullPageLoaderProvider } from '@shared/components/JoyLogoLoader/FullPageLoaderProvider';
import { SuspenseWithPageLoader } from '@shared/core/react/SuspenseWithPageLoader';

export const appsCsr = async () => {
  ReactDOM.render(
    <ErrorBoundary>
      <FullPageLoaderProvider>
        <SuspenseWithPageLoader loaderKey="appCsr">
          <BrowserRouter>
            <App routes={routes} />
          </BrowserRouter>
        </SuspenseWithPageLoader>
      </FullPageLoaderProvider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
};
