import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';
import { animationTransition } from '@shared/utils/animationTransition';
import { boxShadowValuesV2 } from '@shared/utils/style/dropShadows';
import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledExpandableChecklist = styled(Box)`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  transition: ${animationTransition('grid-template-rows', 'opacity')};
  .expanded & {
    grid-template-rows: 1fr;
    opacity: 1;
  }
`;

export const StyledParticleJoyLogo = styled(ParticleJoyLogo)`
  padding: 0;
  cursor: default;
  svg,
  canvas {
    height: 56px;
    width: inherit;
  }
`;

export const ChecklistCardContainer = styled(Flex)``;
const checklistCardContainerStyles: StyleSystemProps = {
  boxShadow: boxShadowValuesV2.xxxl,
  backgroundColor: 'white',
  borderRadius: pxToRem(12),
  width: '100%',
  position: 'relative',
  flexDirection: 'column',
  overflow: 'hidden'
};

export const styles = {
  checklistCardContainer: checklistCardContainerStyles
};
