import React from 'react';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { useTranslation } from '@shared/core';
import { dialogOverrides, actionsButtonsWidth, StyledActionButtion } from './InviteAdminDialog.styles';
import { InviteAdminView } from './components/InviteAdminView';

export interface User {
  id: string;
  email: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  emailIsVerified: boolean;
  auth0Id: string;
}

export interface Invite {
  id?: string;
  email?: string;
  name?: string;
}

export interface InviteAdminDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
  title: string;
  isLoading?: boolean;
  owner?: Maybe<User>;
  collaborators?: User[];
  invitations?: Invite[];
  onInviteCollaborator?: () => void;
  onResendInvitation?: (id: string) => void;
  onRemoveCollaborator?: (auth0Id: string, name: string, email: Maybe<string>) => void;
  onCancelInvitation?: (id: string, name: string, email?: string) => void;
  onTransferOwnership?: (auth0Id: string, name: string, email: Maybe<string>) => void;
  emailIsVerified?: boolean;
  emailVerificationUrl?: string;
  isDialogView?: boolean;
}

const InviteAdminDialog: React.FC<InviteAdminDialogProps> = ({
  isOpen = true,
  onClose,
  title,
  isLoading,
  owner,
  collaborators = [],
  invitations = [],
  onInviteCollaborator,
  onResendInvitation,
  onRemoveCollaborator,
  onCancelInvitation,
  onTransferOwnership,
  emailIsVerified,
  emailVerificationUrl = '',
  isDialogView = true
}) => {
  const { t2 } = useTranslation('joykit');
  const translations = t2('inviteAdmin');

  return (
    <>
      {isDialogView ? (
        <ResponsiveDialog
          title={title}
          isOpen={isOpen}
          onClose={onClose}
          dialogOverrides={dialogOverrides}
          renderFooter={() => (
            <>
              <StyledActionButtion intent="neutral" onClick={onClose} width={actionsButtonsWidth}>
                {translations.done}
              </StyledActionButtion>
            </>
          )}
        >
          <InviteAdminView
            collaborators={collaborators}
            emailIsVerified={emailIsVerified}
            emailVerificationUrl={emailVerificationUrl}
            invitations={invitations}
            isLoading={isLoading}
            onCancelInvitation={onCancelInvitation}
            onInviteCollaborator={onInviteCollaborator}
            onRemoveCollaborator={onRemoveCollaborator}
            onResendInvitation={onResendInvitation}
            onTransferOwnership={onTransferOwnership}
            owner={owner}
            isDialogView={true}
          />
        </ResponsiveDialog>
      ) : (
        <InviteAdminView
          collaborators={collaborators}
          emailIsVerified={emailIsVerified}
          emailVerificationUrl={emailVerificationUrl}
          invitations={invitations}
          isLoading={isLoading}
          onCancelInvitation={onCancelInvitation}
          onInviteCollaborator={onInviteCollaborator}
          onRemoveCollaborator={onRemoveCollaborator}
          onResendInvitation={onResendInvitation}
          onTransferOwnership={onTransferOwnership}
          owner={owner}
          isDialogView={false}
        />
      )}
    </>
  );
};

export { InviteAdminDialog };
